import Test from "./resources/Test";
import Home from "./resources/Home";
import Member from "./resources/Member";
import Terms from "./resources/Terms";
import Board from "./resources/Board";
import Common from "./resources/Common";
import Payment from "./resources/Payment";

const API = {
  Test: Test,
  Member: Member,
  Home: Home,
  Terms: Terms,
  Board: Board,
  Common: Common,
  Payment: Payment,
};

export default API;
