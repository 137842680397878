import Storage from "./Storage";
import Regexp from "./Regexp";
import Form from "./Form";
import Variables from "./Variables";

const Util = {
  Form: Form,
  Storage: Storage,
  Regexp: Regexp,
  Variables: Variables,
};

export default Util;
