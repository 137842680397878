import React from "react";
import Routers from "./Routers";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import Provider from "./Provider";
import "./styles/index.scss";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ModalLayer, SnackbarLayer } from "./layers";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#11cb5f",
    },
  },
});

const App = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <Provider>
          <ModalLayer></ModalLayer>
          <SnackbarLayer></SnackbarLayer>
          <Routers></Routers>
        </Provider>
      </ThemeProvider>
    </I18nextProvider>
  );
};

export default App;
