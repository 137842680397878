import React, { useEffect, useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { UserContext, SnackbarContext, ModalContext } from "../contexts";
import { useInput, usePagination } from "../hooks";
import { FormInput, Button, Paging, Board } from "../components";
import API from "../api";
import Util from "../util";
import { useTranslation } from "react-i18next";

export const DownloadsPage = (props) => {
  const { t } = useTranslation(); // useTranslation 훅을 사용하여 t 함수를 가져옴
  const params = useParams();
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    API.Common.GetProgramLinks().then(
      (res) => {
        console.log("GetProgramLinks", res);
        setData(res.data);
      },
      (err) => {
        console.log("err", err);
      }
    );
  }, []);

  if (!data) {
    return (
      <article className="board-detail-page">
        <div className="board__wrapper">
          <div className="loader big color"></div>
        </div>
      </article>
    );
  }
  return (
    <article className="downloads-page">
      <div className="downloads__container">
        <ul>
          {data.map((item, index) => {
            if (item.isOpen) {
              return (
                <li
                  key={"downloads-" + item.region}
                  className={item.isOpen ? "open" : "closed"}
                  onClick={() => {
                    if (item.isOpen) {
                      window.open(item.clientUrl, "_blank");
                    }
                  }}
                >
                  <div className="icon__box">
                    <img src={item.iconUrl}></img>
                  </div>
                  <div className="title__box">
                    <span>
                      {item.title + " 프로그램 다운로드"}
                      {item.isOpen ? "" : "(예정)"}
                    </span>
                  </div>
                </li>
              );
            }
          })}
        </ul>
      </div>
    </article>
  );
};
