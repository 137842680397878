import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserContext, SnackbarContext } from "../../contexts";
import { useInput, usePagination } from "../../hooks";
import {
  FormInput,
  Button,
  ToastEditor,
  FileMultipleUploader,
} from "../../components";
import API from "../../api";
import Util from "../../util";
import { useTranslation } from "react-i18next";

export const BoardAddPage = (props) => {
  const { t } = useTranslation(); // useTranslation 훅을 사용하여 t 함수를 가져옴

  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { updateToken } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [form, onChangeForm, resetForm] = useInput({
    title: "",
    content: "",
    fileList: [],
  });

  const requestAdd = () => {
    let body = {
      title: form.title,
      content: form.content,
      fileList: form.fileList,
    };

    if (!body.title) {
      updateSnackbar({
        type: "error",
        open: true,
        message: t("free_board.add.alert.no_title"),
      });
      return;
    }

    if (!body.content) {
      updateSnackbar({
        type: "error",
        open: true,
        message: t("free_board.add.alert.no_content"),
      });
      return;
    }

    API.Board.PostFreeBoard(body).then(
      (res) => {
        console.log("res", res);
        let result = res.data;
        console.log("result", result);
        history.push("/board/detail/" + result.no);
      },
      (err) => {
        updateSnackbar({
          type: "error",
          open: true,
          message: err.data.message,
        });
      }
    );

    console.log("requestAdd", body);
  };

  return (
    <article className="board-add-page">
      <div className="board__wrapper">
        <section className="title__box">
          <h3>{t("free_board.add.title")}</h3>
        </section>

        <section className="form__box">
          <FormInput
            label={t("form.label.title")}
            value={form.title}
            name={"title"}
            onChange={onChangeForm}
          ></FormInput>
          <ToastEditor
            value={form.content}
            onChange={onChangeForm}
            name={"content"}
            meta={{
              label: t("form.label.content"),
            }}
          ></ToastEditor>
          <FileMultipleUploader
            value={form.fileList}
            onChange={onChangeForm}
            name="fileList"
            meta={{
              label: t("system.label.file"),
              max: 10,
              path: "free_board",
            }}
          ></FileMultipleUploader>
        </section>

        <section className="button__box">
          <Button
            label={t("free_board.add.button.label")}
            onClick={() => {
              requestAdd();
            }}
          ></Button>
        </section>
      </div>
    </article>
  );
};
