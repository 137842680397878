import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserContext, SnackbarContext } from "../contexts";
import { useInput } from "../hooks";
import { FormInput, Button } from "../components";
import API from "../api";
import Util from "../util";
import { useTranslation } from "react-i18next";

export const LoginPage = (props) => {
  const { t } = useTranslation(); // useTranslation 훅을 사용하여 t 함수를 가져옴

  const history = useHistory();
  const { updateToken } = useContext(UserContext);
  const { updateSnackbar } = useContext(SnackbarContext);
  const [save, setSave] = useState(false);
  console.log("[render - LoginPage]", history);
  const [form, onChangeForm, resetForm] = useInput({ id: "", pw: "" });
  console.log("[render - form afer]", history);

  const requestLogin = () => {
    console.log("requestLogin");

    if (!Util.Regexp.EMAIL.test(form.id)) {
      updateSnackbar({
        type: "error",
        open: true,
        message: t("login.alert.id_error"),
      });
      return;
    }

    let body = {
      id: form.id,
      password: form.pw,
      isAutoSave: save,
    };
    API.Member.PutLogin(body).then(
      (res) => {
        console.log("Res", res);
        updateToken(res.data.token);

        if (res.data.isForcePassword) {
          updateSnackbar({
            type: "info",
            open: true,
            message: t("login.alert.init_password"),
          });
          history.push("/user/reset_pw");
        } else {
          history.push("/");
        }
        // updateToken(res.data.token);
        // history.push("/");
      },
      (err) => {
        updateSnackbar({
          type: "error",
          open: true,
          message: err.data.message,
        });
      }
    );
  };

  return (
    <article className="login-page">
      <section className="login__container card">
        <section className="logo__box">
          <div
            className="ico logo big"
            onClick={() => {
              history.push("/");
            }}
          ></div>
        </section>
        <section className="input__box">
          <FormInput
            label={t("form.label.id")}
            value={form.id}
            name={"id"}
            onChange={onChangeForm}
            placeholder={t("form.placeholder.id")}
          ></FormInput>
          <FormInput
            label={t("form.label.password")}
            type={"password"}
            value={form.pw}
            name={"pw"}
            onChange={onChangeForm}
            placeholder={t("form.placeholder.password")}
            onEnter={requestLogin}
          ></FormInput>
        </section>
        <div className="save__box">
          <div
            onClick={() => {
              setSave(!save);
            }}
            className={save ? "circle on" : "circle"}
          ></div>
          <span
            onClick={() => {
              setSave(!save);
            }}
          >
            자동 로그인 설정
          </span>
        </div>

        <section className="button__box">
          <Button
            label={t("login.button.login_label")}
            onClick={requestLogin}
          ></Button>
        </section>
        <section className="option__box">
          <span
            onClick={() => {
              history.push("/user/find_pw");
            }}
          >
            {t("login.guide.reset_password")}
          </span>
        </section>

        <section className="signup-button__box">
          <header>
            <div className="line left"></div>
            <span>{t("login.guide.signup")}</span>
            <div className="line right"></div>
          </header>
          <Button
            label={t("login.button.signup_label")}
            onClick={() => {
              history.push("/user/signup");
            }}
          ></Button>
        </section>
      </section>
    </article>
  );
};
