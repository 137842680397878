const Regexp = {
  EMAIL: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,6}$/,
  ALL20: /^.{0,20}$"/,
  PHONE: /^01[0-9]{8,9}$/,
  MAX999: /^([1-9][0-9]{0,2}|999)$/,
  KLAYTN_ADDRESS: /^0x[a-fA-F0-9]{40}$/,
  // ID: /^(?=.*\d)(?=.*[a-zA-Z])[0-9a-zA-Z]{6,12}$/,
  ID: /^[a-z0-9]{6,12}$/,
  Password: /^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{8,15}$/,
  KLAY: /^\d{1,15}(\.\d{1,2})?$/, // opensea base
  FEE: /^\d{1,3}(\.\d{1,1})?$/, // opensea base
  SWAP_DICIMAL: /^\d{0,15}(\.?)(\.\d{1,8})?$/,
  // POSITIVE_NUMBER: /^\+?(0|[1-9]\d*)$/,
  SOL: /^\d{1,15}(\.\d{1,4})?$/, // opensea base
  POSITIVE_NUMBER: /^[1-9]\d*$/,
};

export default Regexp;
