import React, { useState, useEffect } from "react";
import { ModalContext } from "../contexts";

export const ModalProvider = (props) => {
  const { children } = props;
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({
    type: "",
  });

  const updateModalOpen = (value) => {
    setOpen(value);
  };

  const updateModalData = (value) => {
    setData(value);
  };

  const closeModal = () => {
    setOpen(false);
    setData({
      type: "",
    });
  };

  return (
    <ModalContext.Provider
      value={{
        modalOpen: open,
        modalData: data,
        updateModalOpen: updateModalOpen,
        updateModalData: updateModalData,
        closeModal: closeModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
