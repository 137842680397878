import React, { useContext, useState } from "react";
import { UserContext, SnackbarContext, ModalContext } from "../../contexts";
import {
  FormInput,
  Button,
  ToastEditor,
  FileMultipleUploader,
} from "../../components";
import { useInput } from "../../hooks";
import API from "../../api";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import Util from "../../util";

export const ModalPoint = (props) => {
  const { t } = useTranslation(); // useTranslation 훅을 사용하여 t 함수를 가져옴

  const {
    modalOpen,
    modalData,
    updateModalOpen,
    updateModalData,
    closeModal,
  } = useContext(ModalContext);
  const [form, onChangeForm, resetForm] = useInput({
    toMemberId: "",
    toMemberCode: "",
    amount: "",
  });
  const { updateSnackbar } = useContext(SnackbarContext);
  const [referralInfo, setReferralInfo] = useState(null);
  const [referralCodeInfo, setReferralCodeInfo] = useState(null);

  console.log("ModalConfirm modalData", modalData);

  const requsetGift = () => {
    console.log("requsetGift");

    if (!form.amount) {
      updateSnackbar({
        type: "error",
        open: true,
        message: t("modal.gift.alert.no_point"),
      });
      return;
    }

    let body = {
      toMemberId: form.toMemberId,
      toMemberCode: form.toMemberCode,
      amount: form.amount,
    };

    API.Member.PostPointTransfer(body).then(
      (res) => {
        updateSnackbar({
          type: "success",
          open: true,
          message: t("modal.gift.alert.success"),
        });
        closeModal();
        updateModalData({
          type: "mypage-point-refresh",
        });
      },
      (err) => {
        updateSnackbar({
          type: "error",
          open: true,
          message: err.data.message,
        });
      }
    );
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      // 1초 뒤에 input 값 확인하는 함수 호출
      console.log("Input value:", form.toMemberId);

      if (!form.toMemberId) {
        return;
      }
      API.Common.GetUserId(form.toMemberId).then(
        (res) => {
          console.log("res", res);
          if (res.data.member) {
            setReferralInfo(res.data.member);
            updateSnackbar({
              type: "info",
              open: true,
              message: t("membership.alert.referral_success"),
            });
          } else {
            setReferralInfo(res.data.member);

            updateSnackbar({
              type: "error",
              open: true,
              message: t("membership.alert.referral_error"),
            });
          }
        },
        (err) => {
          console.log("err", err);
          setReferralInfo(null);
          updateSnackbar({
            type: "error",
            open: true,
            message: err.data.message,
          });
        }
      );
    }, 1000);

    // cleanup 함수에서 타이머 해제
    return () => {
      clearTimeout(timerId);
    };
  }, [form.toMemberId]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      // 1초 뒤에 input 값 확인하는 함수 호출
      console.log("Input value:", form.toMemberCode);

      if (!form.toMemberCode) {
        return;
      }
      API.Common.GetUserIdCode(form.toMemberCode).then(
        (res) => {
          console.log("res", res);
          if (res.data) {
            setReferralCodeInfo(res.data);
            updateSnackbar({
              type: "info",
              open: true,
              message: t("membership.alert.referral_success"),
            });
          } else {
            setReferralCodeInfo(null);
            updateSnackbar({
              type: "error",
              open: true,
              message: t("membership.alert.referral_code_error"),
            });
          }
        },
        (err) => {
          console.log("err", err);
          setReferralCodeInfo(null);
          updateSnackbar({
            type: "error",
            open: true,
            message: err.data.message,
          });
        }
      );
    }, 1000);

    // cleanup 함수에서 타이머 해제
    return () => {
      clearTimeout(timerId);
    };
  }, [form.toMemberCode]);

  return (
    <article className="modal-point">
      <section className="header__box">
        <h4>{modalData.title}</h4>
      </section>
      <section className="content__box">
        <span>{modalData.content}</span>
      </section>

      <section className="form__box">
        <FormInput
          label={t("modal.gift.form.label.id")}
          value={form.toMemberId}
          name={"toMemberId"}
          onChange={onChangeForm}
          placeholder={t("form.placeholder.id")}
        ></FormInput>
        {referralInfo && (
          <div className="member-info__box">
            <ul>
              <li>
                <span>
                  {t("membership.request.label.recommend_name")} :{" "}
                  <b>{Util.Form.masking(referralInfo.name)}</b>
                </span>
              </li>
              <li>
                <span>
                  {t("membership.request.label.recommend_nick")} :{" "}
                  <b>{referralInfo.nick}</b>
                </span>
              </li>
            </ul>
          </div>
        )}
        <FormInput
          label={t("modal.gift.form.label.code")}
          value={form.toMemberCode}
          name={"toMemberCode"}
          onChange={onChangeForm}
          placeholder={t("form.placeholder.code")}
        ></FormInput>
        {referralCodeInfo && (
          <div className="member-info__box">
            <ul>
              <li>
                <span>
                  {t("membership.request.label.recommend_name")} :{" "}
                  <b>{Util.Form.masking(referralCodeInfo.name)}</b>
                </span>
              </li>
              <li>
                <span>
                  {t("membership.request.label.recommend_nick")} :{" "}
                  <b>{referralCodeInfo.nick}</b>
                </span>
              </li>
            </ul>
          </div>
        )}
        <FormInput
          label={t("modal.gift.form.label.point")}
          value={form.amount}
          name={"amount"}
          onChange={onChangeForm}
          placeholder={t("modal.gift.form.placeholder.point")}
        ></FormInput>
      </section>

      <section className="guide-text__box">
        <span>{t("modal.gift.guide_text")}</span>
      </section>

      <section className="button__box">
        <Button
          type="cancle"
          label={t("modal.gift.cancel")}
          onClick={() => {
            closeModal();
          }}
        ></Button>
        <Button label={t("modal.gift.confirm")} onClick={requsetGift}></Button>
      </section>
    </article>
  );
};
