import { BASE_URL } from "../Config";
import AxiosInstance from "../AxiosInstance";
import Util from "../../util";

const PATH = "member/";

const PutJoin = (body) => {
  const url = BASE_URL + PATH + "join";
  return AxiosInstance.put(url, body);
};

const PutLogin = (body) => {
  const url = BASE_URL + PATH + "login";
  return AxiosInstance.put(url, body);
};

const PutFindPassword = (body) => {
  const url = BASE_URL + PATH + "find_password";
  return AxiosInstance.put(url, body);
};

const PutResetPassword = (body) => {
  const url = BASE_URL + PATH + "password";
  return AxiosInstance.put(url, body);
};

const Get = () => {
  const url = BASE_URL + PATH;
  return AxiosInstance.get(url);
};

const Put = (body) => {
  const url = BASE_URL + PATH;
  return AxiosInstance.put(url, body);
};

//qna

const GetInquiry = (params) => {
  const url = BASE_URL + PATH + "inquiry" + Util.Form.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetInquiryDetail = (slug) => {
  const url = BASE_URL + PATH + "inquiry/" + slug;
  return AxiosInstance.get(url);
};
const PutInquiry = (body) => {
  const url = BASE_URL + PATH + "inquiry";
  return AxiosInstance.put(url, body);
};

const PutInquiryDetail = (slug, body) => {
  const url = BASE_URL + PATH + "inquiry/" + slug;
  return AxiosInstance.put(url, body);
};

const DeleteInquiry = (slug) => {
  const url = BASE_URL + PATH + "inquiry/" + slug;
  return AxiosInstance.delete(url);
};

// pay, point

const GetPayment = (params) => {
  const url = BASE_URL + PATH + "payment/" + Util.Form.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetPoint = (params) => {
  const url = BASE_URL + PATH + "point/" + Util.Form.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const PostPointTransfer = (body) => {
  const url = BASE_URL + PATH + "point/transfer";
  return AxiosInstance.post(url, body);
};

//calculate

const GetCalculte = (params) => {
  const url =
    BASE_URL + PATH + "point/calculate/" + Util.Form.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const PostPointCalculte = (body) => {
  const url = BASE_URL + PATH + "point/calculate";
  return AxiosInstance.post(url, body);
};

const PostSecession = (body) => {
  const url = BASE_URL + PATH + "withdrawal";
  return AxiosInstance.post(url, body);
};

const Member = {
  Get: Get,
  Put: Put,
  PutJoin: PutJoin,
  PutLogin: PutLogin,
  PutFindPassword: PutFindPassword,
  PutResetPassword: PutResetPassword,
  GetInquiry: GetInquiry,
  PutInquiry: PutInquiry,
  PutInquiryDetail: PutInquiryDetail,
  DeleteInquiry: DeleteInquiry,
  GetInquiryDetail: GetInquiryDetail,
  GetPayment: GetPayment,
  GetPoint: GetPoint,
  PostPointTransfer: PostPointTransfer,
  GetCalculte: GetCalculte,
  PostPointCalculte: PostPointCalculte,
  PostSecession: PostSecession,
};

export default Member;
