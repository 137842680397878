import React, { useContext } from "react";
import { UserContext, SnackbarContext, ModalContext } from "../../contexts";
import {
  FormInput,
  Button,
  ToastEditor,
  FileMultipleUploader,
} from "../../components";
export const ModalConfirm = (props) => {
  const {
    modalOpen,
    modalData,
    updateModalOpen,
    updateModalData,
    closeModal,
  } = useContext(ModalContext);

  console.log("ModalConfirm modalData", modalData);

  return (
    <article className="modal-confirm">
      <section className="header__box">
        <h4>{modalData.title}</h4>
      </section>
      <section className="content__box">
        <span>{modalData.content}</span>
      </section>
      <section className="button__box">
        <Button
          type="cancle"
          label="취소"
          onClick={() => {
            closeModal();
          }}
        ></Button>
        <Button
          label="확인"
          onClick={() => {
            modalData.onConfirm();
          }}
        ></Button>
      </section>
    </article>
  );
};
