import React, { useContext, useEffect } from "react";
import { ModalContext } from "../contexts";
import Modal from "@mui/material/Modal";
import {
  ModalDelete,
  ModalConfirm,
  ModalPoint,
  ModalCalculate,
  ModalSecession,
} from "../components";

export const ModalLayer = () => {
  const {
    modalOpen,
    modalData,
    updateModalOpen,
    updateModalData,
    closeModal,
  } = useContext(ModalContext);

  console.log("LayerModal", modalData);

  if (modalOpen) {
    const { type, title, termsType } = modalData;
    switch (type) {
      case "test": {
        return (
          <Modal
            open={modalOpen}
            onClose={() => {
              closeModal();
            }}
          >
            <article className="modal-layer"></article>
          </Modal>
        );
      }
      case "delete": {
        return (
          <Modal
            open={modalOpen}
            onClose={() => {
              closeModal();
            }}
          >
            <article className="modal-layer">
              <ModalDelete></ModalDelete>
            </article>
          </Modal>
        );
      }
      case "confirm": {
        return (
          <Modal
            open={modalOpen}
            onClose={() => {
              closeModal();
            }}
          >
            <article className="modal-layer">
              <ModalConfirm></ModalConfirm>
            </article>
          </Modal>
        );
      }
      case "point": {
        return (
          <Modal
            open={modalOpen}
            onClose={() => {
              closeModal();
            }}
          >
            <article className="modal-layer">
              <ModalPoint></ModalPoint>
            </article>
          </Modal>
        );
      }
      case "calculate": {
        return (
          <Modal
            open={modalOpen}
            onClose={() => {
              closeModal();
            }}
          >
            <article className="modal-layer">
              <ModalCalculate></ModalCalculate>
            </article>
          </Modal>
        );
      }
      case "secession": {
        return (
          <Modal
            open={modalOpen}
            onClose={() => {
              closeModal();
            }}
          >
            <article className="modal-layer">
              <ModalSecession></ModalSecession>
            </article>
          </Modal>
        );
      }
      default: {
        return null;
      }
    }
  } else {
    return null;
  }
};
