import { useState, useCallback } from "react";
import _ from "lodash";

export const usePagination = (initialPage, initialTotalPage) => {
  const [page, setPage] = useState(initialPage);
  const [totalPage, setTotalPage] = useState(initialTotalPage);
  const [totalCount, setTotalCount] = useState(0);

  // change
  const onChange = (e, newPage) => {
    setPage(newPage);
  };

  return [
    page,
    totalPage,
    totalCount,
    onChange,
    setPage,
    setTotalPage,
    setTotalCount,
  ];
};
