import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

export const Button = (props) => {
  const history = useHistory();
  const { type, label, onClick, disabled, style } = props;

  const buildContainerClassname = () => {
    let classname = "basic-button";

    if (type) {
      classname += " " + type;
    }
    return classname;
  };

  return (
    <button
      disabled={disabled}
      style={style}
      className={buildContainerClassname()}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <span>{label}</span>
    </button>
  );
};
