import React, { useContext, useState } from "react";
import { UserContext, SnackbarContext, ModalContext } from "../../contexts";
import {
  FormInput,
  Button,
  ToastEditor,
  FileMultipleUploader,
} from "../../components";
import { useInput } from "../../hooks";
import API from "../../api";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import Util from "../../util";

export const ModalCalculate = (props) => {
  const { t } = useTranslation(); // useTranslation 훅을 사용하여 t 함수를 가져옴

  const {
    modalOpen,
    modalData,
    updateModalOpen,
    updateModalData,
    closeModal,
  } = useContext(ModalContext);
  const { userInfo, refreshUserInfo } = useContext(UserContext);
  const [form, onChangeForm, resetForm] = useInput({
    account: "",
    accountName: "",
    bank: "",
    phone: "",
    amount: "",
  });

  const { updateSnackbar } = useContext(SnackbarContext);

  useEffect(() => {
    if (userInfo) {
      console.log("userInfo", userInfo);

      let user_form = {
        account: userInfo.account,
        accountName: userInfo.accountName,
        bank: userInfo.bank,
        phone: userInfo.phone,
        amount: "",
      };
      console.log("work", user_form);
      resetForm({ ...user_form });
    }
  }, [userInfo]);
  console.log("ModalCalculate modalData", modalData);

  const requsetCalculate = () => {
    console.log("requsetCalculate");

    if (!form.accountName) {
      updateSnackbar({
        type: "error",
        open: true,
        message: t("modal.calculate.alert.no_accountName"),
      });
      return;
    }

    if (!form.bank) {
      updateSnackbar({
        type: "error",
        open: true,
        message: t("modal.calculate.alert.no_bank"),
      });
      return;
    }
    if (!form.account) {
      updateSnackbar({
        type: "error",
        open: true,
        message: t("modal.calculate.alert.no_account"),
      });
      return;
    }
    if (!Number(form.amount)) {
      updateSnackbar({
        type: "error",
        open: true,
        message: t("modal.calculate.alert.no_amount"),
      });
      return;
    }

    let body = {
      accountName: form.accountName,
      account: form.account,
      bank: form.bank,
      phone: form.phone,
      amount: Number(form.amount),
    };

    API.Member.PostPointCalculte(body).then(
      (res) => {
        updateSnackbar({
          type: "success",
          open: true,
          message: t("modal.calculate.alert.success"),
        });
        closeModal();
        updateModalData({
          type: "mypage-calculate-refresh",
        });
      },
      (err) => {
        updateSnackbar({
          type: "error",
          open: true,
          message: err.data.message,
        });
      }
    );
  };

  return (
    <article className="modal-point">
      <section className="header__box">
        <h4>{modalData.title}</h4>
      </section>
      <section className="content__box">
        <span>{modalData.content}</span>
        <span style={{ color: "#777", fontsize: 14 }}>{modalData.guide}</span>
      </section>

      <section className="form__box">
        <FormInput
          label={t("form.label.accountName")}
          value={form.accountName}
          name={"accountName"}
          onChange={onChangeForm}
        ></FormInput>
        <FormInput
          label={t("form.label.bank")}
          value={form.bank}
          name={"bank"}
          onChange={onChangeForm}
        ></FormInput>
        <FormInput
          label={t("form.label.account")}
          value={form.account}
          name={"account"}
          onChange={onChangeForm}
        ></FormInput>
        <FormInput
          label={t("form.label.amount")}
          value={form.amount}
          name={"amount"}
          onChange={onChangeForm}
        ></FormInput>
        <FormInput
          label={t("form.label.phone")}
          value={form.phone}
          name={"phone"}
          onChange={onChangeForm}
        ></FormInput>
      </section>

      <section className="button__box">
        <Button
          type="cancle"
          label={t("modal.calculate.cancel")}
          onClick={() => {
            closeModal();
          }}
        ></Button>
        <Button
          label={t("modal.calculate.confirm")}
          onClick={requsetCalculate}
        ></Button>
      </section>
    </article>
  );
};
