import React, { useEffect, useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { UserContext, SnackbarContext } from "../../contexts";
import { useInput, usePagination } from "../../hooks";
import { FormInput, Button, Paging, Board } from "../../components";
import API from "../../api";
import Util from "../../util";
import { ToastViewer } from "../../components/form/ToastViewer";
import { useTranslation } from "react-i18next";

export const NoticeDetailPage = (props) => {
  const { t } = useTranslation(); // useTranslation 훅을 사용하여 t 함수를 가져옴
  const params = useParams();
  const { slug } = params;
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    API.Board.GetNotcieDetail(slug).then(
      (res) => {
        let result = res.data;
        console.log("Board.GetNotcieDetail", result);
        setData(result);
        setLoading(false);
      },
      (err) => {
        setLoading(false);

        updateSnackbar({
          type: "error",
          open: true,
          message: err.data.message,
        });
      }
    );
  }, [slug]);

  console.log("data", data);
  if (!data) {
    return (
      <article className="board-detail-page">
        <div className="board__wrapper">
          <div className="loader big color"></div>
        </div>
      </article>
    );
  }
  return (
    <article className="board-detail-page">
      <div className="board__wrapper">
        <article className="board__item">
          <section className="title__box">
            <h3>{data.title}</h3>
          </section>
          <section className="viewer__box">
            <ToastViewer value={data.content} meta={{}}></ToastViewer>
          </section>
          {data.fileList && data.fileList.length > 0 && (
            <section className="file__box">
              <header>
                <span>{t("system.label.file")}</span>
              </header>
              <ul>
                {data.fileList.map((item, index) => {
                  return (
                    <li
                      key={"notice-file-" + index}
                      onClick={() => {
                        window.open(item, "_blank");
                      }}
                    >
                      <span>{Util.Form.GetFilename(item)}</span>
                    </li>
                  );
                })}
              </ul>
            </section>
          )}
        </article>

        <section className="button__box">
          <Button
            label={t("notice_detail.button.list")}
            onClick={() => {
              history.goBack();
            }}
          ></Button>
        </section>
      </div>
    </article>
  );
};
