import React from "react";
import { UserProvider, ModalProvider, SnackbarProvider } from "./providers";

const Provider = (props) => {
  const { children } = props;

  return (
    <SnackbarProvider>
      <ModalProvider>
        <UserProvider>{children}</UserProvider>
      </ModalProvider>
    </SnackbarProvider>
  );
};

export default Provider;
