import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserContext, SnackbarContext } from "../../contexts";
import { useInput } from "../../hooks";
import { FormInput, Button } from "../../components";
import API from "../../api";
import Util from "../../util";
import { useTranslation } from "react-i18next";
import queryString from "query-string";

export const UserSignupPage = (props) => {
  const { t } = useTranslation(); // useTranslation 훅을 사용하여 t 함수를 가져옴
  const history = useHistory();
  const { updateToken } = useContext(UserContext);
  console.log("[render - LoginPage]", history);
  const [form, onChangeForm, resetForm] = useInput({
    id: "",
    pw: "",
    pw_check: "",
    name: "",
    nick: "",
    phone: "",
    recommendCode: "",
  });
  console.log("[render - form afer]", history);
  const { updateSnackbar } = useContext(SnackbarContext);
  const [termsCheck, setTermsCheck] = useState(false);
  const searchObject = queryString.parse(history.location.search);
  console.log("searchObject,", searchObject);

  const requestSignup = () => {
    console.log("requestSignup");

    if (!termsCheck) {
      updateSnackbar({
        type: "error",
        open: true,
        message: t("signup.alert.no_terms"),
      });
      return;
    }

    if (!Util.Regexp.EMAIL.test(form.id)) {
      updateSnackbar({
        type: "error",
        open: true,
        message: t("signup.alert.no_id"),
      });
      return;
    }

    if (form.pw !== form.pw_check) {
      updateSnackbar({
        type: "error",
        open: true,
        message: t("signup.alert.not_matched_password"),
      });
      return;
    }

    let body = {
      id: form.id,
      password: form.pw,
      name: form.name,
      nick: form.nick,
      phone: form.phone,
      recommendCode: searchObject.code ? searchObject.code : "",
    };
    console.log("body", body);
    API.Member.PutJoin(body).then(
      (res) => {
        console.log("res", res);
        let result = res.data;
        console.log("result", result);
        //return 후 토큰을 주어야함
        updateToken(result.token);
        history.push("/");
      },
      (err) => {
        updateSnackbar({
          type: "error",
          open: true,
          message: err.data.message,
        });
      }
    );
  };

  return (
    <article className="login-page">
      <section className="login__container card">
        <section className="logo__box">
          <div
            className="ico logo big"
            onClick={() => {
              history.push("/");
            }}
          ></div>
        </section>
        <section className="input__box">
          <FormInput
            label={t("form.label.id")}
            value={form.id}
            name={"id"}
            onChange={onChangeForm}
            placeholder={t("form.placeholder.id")}
          ></FormInput>
          <FormInput
            label={t("form.label.password")}
            type={"password"}
            value={form.pw}
            name={"pw"}
            onChange={onChangeForm}
            placeholder={t("form.placeholder.password")}
          ></FormInput>
          <FormInput
            label={t("form.label.password_check")}
            type={"password"}
            value={form.pw_check}
            name={"pw_check"}
            onChange={onChangeForm}
          ></FormInput>
          <FormInput
            label={t("form.label.name")}
            value={form.name}
            name={"name"}
            onChange={onChangeForm}
          ></FormInput>
          <FormInput
            label={t("form.label.nick")}
            value={form.nick}
            name={"nick"}
            onChange={onChangeForm}
          ></FormInput>
          {/* <FormInput
            label={t("form.label.phone")}
            value={form.phone}
            name={"phone"}
            onChange={onChangeForm}
            placeholder={t("form.placeholder.phone")}
          ></FormInput> */}
        </section>
        <section className="terms__box">
          <div
            onClick={() => {
              setTermsCheck(!termsCheck);
            }}
            className={termsCheck ? "circle on" : "circle"}
          ></div>
          <span
            onClick={(e) => {
              e.stopPropagation();
              setTermsCheck(!termsCheck);
            }}
          >
            <b
              onClick={() => {
                history.push("/terms?tab=service");
              }}
            >
              이용약관
            </b>{" "}
            및{" "}
            <b
              onClick={() => {
                history.push("/terms?tab=privacy");
              }}
            >
              개인정보처리방침
            </b>
            에 동의합니다.
          </span>
        </section>
        <section className="button__box">
          <Button
            label={t("signup.button.confirm")}
            onClick={requestSignup}
          ></Button>
        </section>
        <section
          className="option__box"
          style={{ justifyContent: "center", marginBottom: 8 }}
        >
          <span
            style={{ marginRight: 24 }}
            onClick={() => {
              history.push("/");
            }}
          >
            {t("signup.button.home")}
          </span>
          <span
            onClick={() => {
              history.push("/login");
            }}
          >
            {t("signup.button.login")}
          </span>
        </section>
      </section>
    </article>
  );
};
