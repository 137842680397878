import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { MainHeader, Navigation, MainFooter } from "../components";

export const MypageLayout = (props) => {
  const history = useHistory();
  const { children } = props;

  return (
    <div className="mypage-layout">
      <section className="content__container">
        <section className="header__box">
          <MainHeader></MainHeader>
        </section>
        <section className="content__box">
          <div className="nav__container">
            <Navigation></Navigation>
          </div>
          <article className="scroll__container">{children}</article>
        </section>
        <section className="footer__box">
          <MainFooter></MainFooter>
        </section>
      </section>
    </div>
  );
};
