import React, { useEffect, useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { UserContext, SnackbarContext, ModalContext } from "../../contexts";
import { useInput, usePagination } from "../../hooks";
import {
  FormInput,
  Button,
  ToastEditor,
  FileMultipleUploader,
  FormArea,
} from "../../components";
import API from "../../api";
import Util from "../../util";
import { useTranslation } from "react-i18next";

export const MypageQnaDetailPage = (props) => {
  const { t } = useTranslation(); // useTranslation 훅을 사용하여 t 함수를 가져옴
  const params = useParams();
  const { slug } = params;
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { userInfo } = useContext(UserContext);
  const { updateModalOpen, updateModalData, closeModal } = useContext(
    ModalContext
  );
  const [form, onChangeForm, resetForm] = useInput({
    title: "",
    content: "",
    fileList: [],
  });

  useEffect(() => {
    setLoading(true);

    API.Member.GetInquiryDetail(slug).then(
      (res) => {
        let result = res.data;
        console.log("Board.GetNotcieDetail", result);
        setData(result);
        setLoading(false);
      },
      (err) => {
        setLoading(false);

        updateSnackbar({
          type: "error",
          open: true,
          message: err.data.message,
        });
      }
    );
  }, [slug]);

  if (!data) {
    return (
      <article className="mypage-qna-detail-page">
        <div className="loader big color"></div>
      </article>
    );
  }

  return (
    <article className="mypage-qna-detail-page">
      <section className="title__box">
        <h3>{t("mypage.qna_detail.title")}</h3>
      </section>

      <section className="form__box">
        <FormInput
          label={t("form.label.title")}
          value={data.title}
          name={"title"}
          disabled={true}
          onChange={onChangeForm}
        ></FormInput>
        <FormArea
          label={t("form.label.content")}
          value={data.content}
          name={"content"}
          disabled={true}
        ></FormArea>
      </section>
      {data.fileList && data.fileList.length > 0 && (
        <section className="file__box">
          <header>
            <span>{t("system.label.file")}</span>
          </header>
          <ul>
            {data.fileList.map((item, index) => {
              return (
                <li
                  key={"notice-file-" + index}
                  onClick={() => {
                    window.open(item, "_blank");
                  }}
                >
                  <span>{Util.Form.GetFilename(item)}</span>
                </li>
              );
            })}
          </ul>
        </section>
      )}

      <secton className="form__box answer">
        {data.answer ? (
          <FormArea
            label={t("mypage.qna_detail.answer.label")}
            value={data.answer}
            name={"answer"}
            disabled={true}
          ></FormArea>
        ) : (
          <div className="answer_wait__box">
            <header className="label__box">
              <span>{t("mypage.qna_detail.answer.label")}</span>
            </header>
            <span className="text">{t("mypage.qna_detail.answer.text")}</span>
          </div>
        )}
      </secton>

      <section className="button__box">
        <Button
          type="delete"
          style={{ marginLeft: 16 }}
          label={t("mypage.qna_detail.button.delete")}
          onClick={() => {
            updateModalData({
              type: "delete",
              title: t("mypage.qna_detail.confirm.title"),
              content: t("mypage.qna_detail.confirm.content"),
              onConfirm: () => {
                console.log("work");
                API.Member.DeleteInquiry(slug).then(
                  (res) => {
                    updateSnackbar({
                      type: "success",
                      open: true,
                      message: t("mypage.qna_detail.confirm.alert_success"),
                    });
                    history.push("/mypage/qna/list");
                    closeModal();
                  },
                  (err) => {
                    closeModal();

                    updateSnackbar({
                      type: "error",
                      open: true,
                      message: err.data.message,
                    });
                  }
                );
              },
            });
            updateModalOpen(true);
          }}
        ></Button>
      </section>
    </article>
  );
};
