import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "../../components";
import { UserContext } from "../../contexts";
import { useTranslation } from "react-i18next";

export const MainFooter = (props) => {
  const history = useHistory();
  const { token, userInfo, clearToken } = useContext(UserContext);
  const { t } = useTranslation(); // useTranslation 훅을 사용하여 t 함수를 가져옴

  console.log("history", history);

  const MENU_LIST = [
    {
      path: "/terms?tab=service",
      label: t("footer.menuList.termsofService"),
    },
    {
      path: "/terms?tab=privacy",
      label: t("footer.menuList.privacyPolicy"),
    },
  ];

  return (
    <article className="main-footer">
      <section className="copyright__box">
        <b>{t("footer.copyright.title")}</b>
        <div className="desc__box">
          <p>{t("footer.copyright.desc1")}</p>
          <p>{t("footer.copyright.desc2")}</p>
          <p>{t("footer.copyright.desc3")}</p>
          <p>{t("footer.copyright.desc4")}</p>
        </div>
      </section>
      <section className="menu__box">
        <ul>
          {MENU_LIST.map((item, index) => {
            return (
              <li
                onClick={() => {
                  history.push(item.path);
                }}
              >
                <span>{item.label}</span>
              </li>
            );
          })}
        </ul>
      </section>
    </article>
  );
};
