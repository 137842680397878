import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export const FormArea = (props) => {
  const {
    type,
    label,
    value,
    name,
    onChange,
    disabled,
    onEnter,
    placeholder,
  } = props;
  const [error, setError] = useState(false);

  const buildContainerClassname = () => {
    let classname = "form-area";

    if (error) {
      classname += " error";
    }

    if (disabled) {
      classname += " disabled";
    }

    return classname;
  };

  return (
    <article className={buildContainerClassname()}>
      {label && (
        <div className="label__box">
          <span>{label}</span>
        </div>
      )}
      <textarea
        disabled={disabled}
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={(e) => {
          onChange(e);
        }}
        onKeyUp={(e) => {
          if (e.keyCode === 13) {
            console.log("enter");
            if (onEnter) {
              onEnter();
            }
          }
        }}
      ></textarea>
    </article>
  );
};
