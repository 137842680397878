import React, { useContext } from "react";
import { UserContext, SnackbarContext, ModalContext } from "../../contexts";
import {
  FormInput,
  Button,
  ToastEditor,
  FileMultipleUploader,
} from "../../components";
import { useInput } from "../../hooks";
import { useTranslation } from "react-i18next";

export const ModalSecession = (props) => {
  const { t } = useTranslation(); // useTranslation 훅을 사용하여 t 함수를 가져옴

  const {
    modalOpen,
    modalData,
    updateModalOpen,
    updateModalData,
    closeModal,
  } = useContext(ModalContext);
  const [form, onChangeForm, resetForm] = useInput({
    reason: "",
  });
  console.log("ModalConfirm modalData", modalData);

  return (
    <article className="modal-point">
      <section className="header__box">
        <h4>{modalData.title}</h4>
      </section>
      <section className="content__box">
        <span>{modalData.content}</span>
      </section>
      <section className="form__box">
        <FormInput
          label={t("form.label.reason")}
          value={form.reason}
          name={"reason"}
          onChange={onChangeForm}
        ></FormInput>
      </section>
      <section className="button__box">
        <Button
          type="cancle"
          label={t("modal.board_delete.button.cancel")}
          onClick={() => {
            closeModal();
          }}
        ></Button>
        <Button
          label={t("modal.secession.button.confirm")}
          disabled={!form.reason}
          onClick={() => {
            modalData.onConfirm(form.reason);
          }}
        ></Button>
      </section>
    </article>
  );
};
