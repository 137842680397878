import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Button, LangSelector } from "../../components";
import { UserContext } from "../../contexts";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Modal from "@mui/material/Modal";

export const MainHeader = (props) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { downloadLink, token, userInfo, clearToken } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const NAV_LIST = [
    {
      path: "/",
      label: t("header.nav.home"),
    },
    {
      path: "/membership",
      label: t("header.nav.membership"),
    },
    {
      path: "/notice/list",
      label: t("header.nav.notice"),
    },
    {
      path: "/membership/list",
      label: t("header.nav.membership_board"),
    },
    // {
    //   path: "/board/list",
    //   label: t("header.nav.free_board"),
    // },
    {
      path: "/downloads",
      label: t("header.nav.downloads"),
    },
    // {
    //   path: "https://cafe.naver.com/quantking",
    //   label: "네이버 카페",
    // },
  ];

  console.log("history", history);
  console.log("i18n.language", i18n.language);
  return (
    <article className="main-header">
      <article className="mobile">
        <section className="lang__box">
          <LangSelector
            options={[
              {
                value: "kr",
                label: "한국어",
              },
              {
                value: "en",
                label: "English",
              },
            ]}
            value={i18n.language}
            onChange={(e) => {
              i18n.changeLanguage(e.value);
            }}
          ></LangSelector>
        </section>
        <section className="logo__box">
          <div
            className="ico logo"
            onClick={() => {
              history.push("/");
            }}
          ></div>
        </section>
        <section className="menu__box">
          <div
            className="ico menu"
            onClick={() => {
              setOpen(true);
            }}
          ></div>
        </section>
      </article>
      <article className="up">
        <div className="header__wrapper">
          <section className="lang__box">
            <LangSelector
              options={[
                {
                  value: "kr",
                  label: "한국어",
                },
                // {
                //   value: "en",
                //   label: "English",
                // },
              ]}
              value={i18n.language}
              onChange={(e) => {
                i18n.changeLanguage(e.value);
              }}
            ></LangSelector>
          </section>
          <section className="outlink__box">
            <ul>
              {/* <li>
                <span
                  onClick={() => {
                    window.open(downloadLink.domestic, "_blank");
                  }}
                >
                  {t("header.nav.program_download_kr")}
                </span>
              </li>
              <li>
                <span
                  onClick={() => {
                    window.open(downloadLink.usa, "_blank");
                  }}
                >
                  {t("header.nav.program_download_en")}
                </span>
              </li> */}
              <li>
                <span
                  onClick={() => {
                    fetch("https://storagequantking.blob.core.windows.net/quantkingfile/QuantkingManual_korea_v1.pdf", {
                      method: "GET",
                      responseType: "blob", // Blob 형태로 응답 받기
                    })
                      .then((response) => response.blob())
                      .then((blob) => {
                        // Blob 객체로부터 다운로드 링크 생성
                        const url = window.URL.createObjectURL(
                          new Blob([blob])
                        );
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", "quantking_menual.pdf");
                        document.body.appendChild(link);
                        link.click();
                        link.parentNode.removeChild(link);
                      })
                      .catch((error) => {
                        console.error("PDF 다운로드에 실패했습니다:", error);
                      });
                  }}
                >
                  {t("header.nav.menual_download_kr")}
                </span>
              </li>
              {i18n.language === "kr" && (
                <li
                  onClick={() => {
                    window.open("https://cafe.naver.com/quantking", "_blank");
                  }}
                >
                  <span>{t("header.nav.naver_cafe")}</span>
                </li>
              )}
            </ul>
          </section>
        </div>
      </article>
      <article className="down">
        <div className="header__wrapper">
          <section className="title__box">
            <div
              className="ico logo"
              onClick={() => {
                history.push("/");
              }}
            ></div>
          </section>
          <section className="option__box">
            <ul>
              {NAV_LIST.map((item, index) => {
                if (i18n.language !== "kr" && index === 3) {
                  return null;
                }

                let active = false;
                if (history.location.pathname === item.path) {
                  active = true;
                }
                return (
                  <li
                    className={active ? "on" : ""}
                    onClick={() => {
                      history.push(item.path);
                    }}
                  >
                    <span>{item.label}</span>
                  </li>
                );
              })}
              {userInfo && (
                <li
                  onClick={() => {
                    history.push("/mypage/qna/list");
                  }}
                >
                  <span>문의하기</span>
                </li>
              )}
            </ul>

            <div className="button__box">
              {userInfo && (
                <>
                  <Button
                    type="header"
                    label={t("header.button.mypage")}
                    onClick={() => {
                      history.push("/mypage/info");
                    }}
                    style={{ marginRight: 16 }}
                  ></Button>
                  <Button
                    type="header line"
                    label={t("header.button.logout")}
                    onClick={() => {
                      clearToken();
                    }}
                  ></Button>
                </>
              )}
              {!userInfo && (
                <>
                  <Button
                    type="header"
                    label={t("header.button.login")}
                    onClick={() => {
                      history.push("/login");
                    }}
                    style={{ marginRight: 16 }}
                  ></Button>
                  <Button
                    type="header line"
                    label={t("header.button.signup")}
                    onClick={() => {
                      history.push("/user/signup");
                    }}
                  ></Button>
                </>
              )}
            </div>
          </section>
        </div>
      </article>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <article className="modal-menu">
          <header>
            <div
              className="ico x_mark"
              onClick={() => {
                setOpen(false);
              }}
            ></div>
          </header>
          <section className="nav-list__box">
            <ul>
              {NAV_LIST.map((item, index) => {
                if (i18n.language !== "kr" && index === 3) {
                  return null;
                }

                let active = false;
                if (history.location.pathname === item.path) {
                  active = true;
                }
                return (
                  <li
                    className={active ? "on" : ""}
                    onClick={() => {
                      history.push(item.path);
                    }}
                  >
                    <span>{item.label}</span>
                  </li>
                );
              })}
              {userInfo && (
                <li
                  onClick={() => {
                    history.push("/mypage/qna/list");
                  }}
                >
                  <span>문의하기</span>
                </li>
              )}
            </ul>
          </section>
          <section className="button__box">
            {userInfo && (
              <>
                <Button
                  type="header"
                  label={t("header.button.mypage")}
                  onClick={() => {
                    history.push("/mypage/info");
                  }}
                  style={{ marginRight: 16 }}
                ></Button>
                <Button
                  type="header line"
                  label={t("header.button.logout")}
                  onClick={() => {
                    clearToken();
                  }}
                ></Button>
              </>
            )}
            {!userInfo && (
              <>
                <Button
                  type="header"
                  label={t("header.button.login")}
                  onClick={() => {
                    history.push("/login");
                  }}
                  style={{ marginRight: 16 }}
                ></Button>
                <Button
                  type="header line"
                  label={t("header.button.signup")}
                  onClick={() => {
                    history.push("/user/signup");
                  }}
                ></Button>
              </>
            )}
          </section>
        </article>
      </Modal>
    </article>
  );
};
