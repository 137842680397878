import React, { useContext, useEffect, useRef, useState } from "react";
import "@toast-ui/editor/dist/toastui-editor.css";
import { Viewer } from "@toast-ui/react-editor";
import Handler from "../../Handler";
import API from "../../api";

export const ToastViewer = (props) => {
  const { value, onChange, meta, onEnter } = props;

  // console.log('ToastViewer value', value);
  const renderRequired = () => {
    if (meta && meta.required) {
      if (meta.classNAme === "point") {
        return <div className="ico required"></div>;
      } else {
        return <span>*</span>;
      }
    }
  };

  const buildContainerClassname = () => {
    let classname = "form-toast-viewer";

    return classname;
  };

  if (!value) {
    return null;
  }

  return (
    <article
      className={buildContainerClassname()}
      style={meta && meta.style ? { ...meta.style } : {}}
    >
      {meta.label && (
        <header>
          <span>
            {meta.label}
            {renderRequired()}
          </span>
        </header>
      )}
      <div className="editor__box">
        <Viewer initialValue={value} />
      </div>
    </article>
  );
};
