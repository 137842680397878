import React, { useContext } from "react";
import { UserContext, SnackbarContext, ModalContext } from "../../contexts";
import {
  FormInput,
  Button,
  ToastEditor,
  FileMultipleUploader,
} from "../../components";
import { useTranslation } from "react-i18next";

export const ModalDelete = (props) => {
  const {
    modalOpen,
    modalData,
    updateModalOpen,
    updateModalData,
    closeModal,
  } = useContext(ModalContext);
  const { t } = useTranslation(); // useTranslation 훅을 사용하여 t 함수를 가져옴

  console.log("modalData", modalData);

  return (
    <article className="modal-delete">
      <section className="header__box">
        <h4>{modalData.title}</h4>
      </section>
      <section className="content__box">
        <span>{modalData.content}</span>
      </section>
      <section className="button__box">
        <Button
          type="cancle"
          label={t("modal.board_delete.button.cancel")}
          onClick={() => {
            closeModal();
          }}
        ></Button>
        <Button
          type="delete"
          label={t("modal.board_delete.button.confirm")}
          onClick={() => {
            modalData.onConfirm();
          }}
        ></Button>
      </section>
    </article>
  );
};
