import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserContext, SnackbarContext } from "../../contexts";
import { useInput } from "../../hooks";
import { FormInput, Button } from "../../components";
import API from "../../api";
import Util from "../../util";
import { useTranslation } from "react-i18next";

export const UserResetPwPage = (props) => {
  const { t } = useTranslation(); // useTranslation 훅을 사용하여 t 함수를 가져옴
  const history = useHistory();
  const { updateToken } = useContext(UserContext);
  console.log("[render - LoginPage]", history);
  const [form, onChangeForm, resetForm] = useInput({ pw: "", pw_check: "" });
  console.log("[render - form afer]", history);
  const { updateSnackbar } = useContext(SnackbarContext);

  const requestResetPw = () => {
    console.log("requestResetPw");

    if (form.pw !== form.pw_check) {
      updateSnackbar({
        type: "error",
        open: true,
        message: t("signup.alert.not_matched_password"),
      });
      return;
    }

    let body = {
      newPassword: form.pw,
    };
    API.Member.PutResetPassword(body).then(
      (res) => {
        console.log("res", res);
        updateSnackbar({
          type: "success",
          open: true,
          message: t("reset_pw.alert.reset_success"),
        });
        history.push("/");
      },
      (err) => {
        updateSnackbar({
          type: "error",
          open: true,
          message: err.data.message,
        });
      }
    );
  };

  return (
    <article className="login-page">
      <section className="login__container card">
        <section className="logo__box">
          <div
            className="ico logo big"
            onClick={() => {
              history.push("/");
            }}
          ></div>
        </section>
        <section className="input__box">
          <FormInput
            label={t("form.label.new_password")}
            type={"password"}
            value={form.pw}
            name={"pw"}
            onChange={onChangeForm}
          ></FormInput>
          <FormInput
            type={"password"}
            label={t("form.label.new_password_check")}
            value={form.pw_check}
            name={"pw_check"}
            onChange={onChangeForm}
          ></FormInput>
        </section>
        <section className="button__box">
          <Button
            label={t("reset_pw.button.confirm")}
            onClick={requestResetPw}
          ></Button>
        </section>
        <section
          className="option__box"
          style={{ justifyContent: "center", marginBottom: 8 }}
        >
          <span
            style={{ marginRight: 24 }}
            onClick={() => {
              history.push("/");
            }}
          >
            {t("signup.button.home")}
          </span>
          <span
            onClick={() => {
              history.push("/mypage/info");
            }}
          >
            {t("reset_pw.button.mypage")}
          </span>
        </section>
      </section>
    </article>
  );
};
