import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import ln_kr from "../../styles/images/ln_kr.jpg";
import ln_en from "../../styles/images/ln_en.jpg";
import { useTranslation } from "react-i18next";
import ClickAwayListener from "@mui/material/ClickAwayListener";

export const LangSelector = (props) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { value, options } = props;
  const [open, setOpen] = useState(false);
  const buildContainerClassname = () => {
    let classname = "lang-selector";

    return classname;
  };

  const getLabelImg = (value) => {
    switch (value) {
      case "kr": {
        return <img src={ln_kr}></img>;
      }
      case "en": {
        return <img src={ln_en}></img>;
      }
    }
  };

  const getLabelText = (value) => {
    for (let i in options) {
      let target = options[i];

      if (target.value === value) {
        return target.label;
      }
    }
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        setOpen(false);
      }}
    >
      <article
        className={buildContainerClassname()}
        // onClick={() => {
        //   setOpen(!open);
        // }}
      >
        <div className="img__box">{getLabelImg(value)}</div>
        <span className="label">{getLabelText(value)}</span>
        <div className={open ? "open__box on" : "open__box"}>
          <ul>
            {options.map((item, index) => {
              return (
                <li
                  onClick={() => {
                    i18n.changeLanguage(item.value);
                  }}
                >
                  <div className="img__box">{getLabelImg(item.value)}</div>
                  <span>{item.label}</span>
                </li>
              );
            })}
          </ul>
        </div>
      </article>
    </ClickAwayListener>
  );
};
