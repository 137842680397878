import { BASE_URL, COMMON_URL } from "../Config";
import axios from "axios";
import Util from "../../util";
import AxiosInstance from "../AxiosInstance";

const PostFile = (formData) => {
  console.log("work");
  const url = COMMON_URL + "file";
  const headers = {
    "content-type": "multipart/form-data",
  };
  return axios.post(url, formData, headers);
};

const GetUserId = (id) => {
  console.log("work");
  const url = COMMON_URL + "member/" + id;
  return AxiosInstance.get(url);
};

const GetUserIdCode = (code) => {
  console.log("work");
  const url = COMMON_URL + "member/referral/" + code;
  return AxiosInstance.get(url);
};

const GetProgramLink = (id) => {
  const url = COMMON_URL + "program/link";
  return AxiosInstance.get(url);
};

const GetProgramLinks = (id) => {
  const url = COMMON_URL + "program/links";
  return AxiosInstance.get(url);
};

const Common = {
  PostFile: PostFile,
  GetUserId: GetUserId,
  GetUserIdCode: GetUserIdCode,
  GetProgramLink: GetProgramLink,
  GetProgramLinks: GetProgramLinks,
};

export default Common;
