import { useState } from "react";

export const useInput = (initialForm) => {
  console.log("useInputs", initialForm);
  const [input, setInput] = useState(initialForm);

  const onChange = (e) => {
    console.log("useInput onChange", e);
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  const reset = (value) => {
    console.log("resetForm", value);
    if (value) {
      setInput(value);
    } else {
      setInput(initialForm);
    }
  };

  return [input, onChange, reset];
};
