import React, { useEffect, useState, useContext, userEffect } from "react";
import { useHistory } from "react-router-dom";
import { UserContext, SnackbarContext, ModalContext } from "../../contexts";
import { useInput, usePagination } from "../../hooks";
import { FormInput, Button, TablePoint, Paging } from "../../components";
import API from "../../api";
import Util from "../../util";
import { useTranslation } from "react-i18next";

export const MypagePointPage = (props) => {
  const { t } = useTranslation(); // useTranslation 훅을 사용하여 t 함수를 가져옴

  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { userInfo, refreshUserInfo } = useContext(UserContext);
  const [list, setList] = useState(null);
  const [
    page,
    totalPage,
    totalCount,
    onPageChange,
    setPage,
    setTotalPage,
    setTotalCount,
  ] = usePagination(1, 10);
  const [loading, setLoading] = useState(false);
  const {
    modalData,
    updateModalOpen,
    updateModalData,
    closeModal,
  } = useContext(ModalContext);

  useEffect(() => {
    refreshUserInfo();
  }, []);

  console.log("userInfo", userInfo);
  useEffect(() => {
    requsetList();
  }, [page]);

  useEffect(() => {
    if (
      modalData &&
      modalData.type &&
      modalData.type === "mypage-point-refresh"
    ) {
      console.log("modalData", modalData);
      requsetList(1);
      updateModalData({ type: "" });
      refreshUserInfo();
    }
  }, [modalData]);
  const requsetList = (init_page) => {
    setLoading(true);
    let params = {
      page: init_page ? init_page : page,
      limit: 10,
    };

    API.Member.GetPoint(params).then(
      (res) => {
        let result = res.data;
        console.log("Member.GetPoint", result);
        setList(result.items);
        setTotalPage(result.meta.totalPages);
        setTotalCount(result.meta.totalCount);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        updateSnackbar({
          type: "error",
          open: true,
          message: err.data.message,
        });
      }
    );
  };

  if (!userInfo) {
    return null;
  }

  return (
    <article className="mypage-point-page">
      <section className="title__box">
        <h3>
          {t("mypage.point.title")} :{" "}
          <b>{Util.Form.formatNumber(userInfo.point)}</b>
        </h3>
        <Button
          onClick={() => {
            updateModalData({
              type: "point",
              title: t("mypage.point.confirm.title"),
              content: t("mypage.point.confirm.content"),
            });
            updateModalOpen(true);
          }}
          label={t("mypage.point.button.gift")}
        ></Button>
      </section>

      <section className="banner__box">
        <header>
          <span>{t("mypage.point.guide.title")} : </span>
        </header>
        <div className="value__box">
          <span>{t("mypage.point.guide.desc")}</span>
        </div>
      </section>
      <section className="table__box">
        <TablePoint data={list} loading={loading}></TablePoint>
      </section>

      <section className="page__box">
        <Paging
          page={page}
          totalPage={totalPage}
          onChange={onPageChange}
        ></Paging>
      </section>
    </article>
  );
};
