import React, { useEffect, useState, useContext, userEffect } from "react";
import { useHistory } from "react-router-dom";
import { UserContext, SnackbarContext } from "../contexts";
import { useInput } from "../hooks";
import { FormInput, Button } from "../components";
import API from "../api";
import Util from "../util";
import queryString from "query-string";

export const TermsPage = (props) => {
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [termsData, setTermsData] = useState(null);
  console.log("history,", history);
  const searchObject = queryString.parse(history.location.search);
  console.log("searchObject,", searchObject);

  useEffect(() => {
    //token validation
    API.Terms.Get().then(
      (res) => {
        console.log("Terms.Get()", res);
        setTermsData(res.data);
      },
      (err) => {
        updateSnackbar({
          type: "error",
          open: true,
          message: err.data.message,
        });
      }
    );
  }, []);

  const renderTitle = () => {
    switch (searchObject.tab) {
      case "service": {
        return <h1>이용약관</h1>;
      }
      case "privacy": {
        return <h1>개인정보처리방침</h1>;
      }
    }
  };

  const renderContent = () => {
    if (!termsData) {
      return null;
    }

    switch (searchObject.tab) {
      case "service": {
        let target_data = null;
        for (let i in termsData) {
          let target = termsData[i];

          if (target.type === "TermsOfUse") {
            target_data = target;
          }
        }

        return (
          <div dangerouslySetInnerHTML={{ __html: target_data.contents }} />
        );
      }
      case "privacy": {
        let target_data = null;
        for (let i in termsData) {
          let target = termsData[i];

          if (target.type === "PrivacyPolicy") {
            target_data = target;
          }
        }
        return (
          <div dangerouslySetInnerHTML={{ __html: target_data.contents }} />
        );
      }
    }
  };

  return (
    <article className="terms-page">
      <section className="logo__box">
        <div
          className="ico logo big"
          onClick={() => {
            history.push("/");
          }}
        ></div>
      </section>
      <section className="title__box">{renderTitle()}</section>
      <section className="content__box">{renderContent()}</section>
    </article>
  );
};
