import { BASE_URL } from "../Config";
import AxiosInstance from "../AxiosInstance";

const PATH = "test/";

const Request = () => {
  const url = BASE_URL + PATH;
  return AxiosInstance.get(url);
};

const Test = {
  Request: Request,
};

export default Test;
