import React, { useEffect, useContext } from 'react';
import { UserContext } from '../contexts';

export const ProtectPage = (props) => {
  const { clearToken } = useContext(UserContext);

  return (
    <article className='login-page'>
      <span>protect</span>
      <div>
        <button
          onClick={() => {
            console.log('clearToken');
            clearToken();
          }}
        >
          logout
        </button>
      </div>
    </article>
  );
};
