import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { MainHeader, Navigation, MainFooter } from "../components";

export const MainLayout = (props) => {
  const history = useHistory();
  const { children } = props;

  return (
    <div className="main-layout">
      <section className="content__container">
        <section className="header__box">
          <MainHeader></MainHeader>
        </section>
        <section
          className="content__box"
          style={{ paddingBottom: history.location.pathname === "/" ? 0 : 80 }}
        >
          <article className="scroll__container">{children}</article>
        </section>
        <section className="footer__box">
          <MainFooter></MainFooter>
        </section>
      </section>
    </div>
  );
};
