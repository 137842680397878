import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Navigation = (props) => {
  const { t } = useTranslation(); // useTranslation 훅을 사용하여 t 함수를 가져옴
  const history = useHistory();

  const buildContainerClassname = () => {
    let classname = "navigation";

    return classname;
  };

  const NAV_LIST = [
    {
      label: t("mypage.nav.info"),
      path: "/mypage/info",
    },
    {
      label: t("mypage.nav.membership"),
      path: "/mypage/membership",
    },
    {
      label: t("mypage.nav.point"),
      path: "/mypage/point",
    },
    {
      label: t("mypage.nav.calculate"),
      path: "/mypage/calculate",
    },
    {
      label: t("mypage.nav.qna"),
      path: "/mypage/qna/list",
    },
  ];

  return (
    <article className={buildContainerClassname()}>
      <ul>
        {NAV_LIST.map((item, index) => {
          let active = false;
          if (history.location.pathname === item.path) {
            active = true;
          }
          return (
            <li
              className={active ? "on" : ""}
              onClick={() => {
                history.push(item.path);
              }}
            >
              <span>{item.label}</span>
            </li>
          );
        })}
      </ul>
    </article>
  );
};
