const BASE_URL =
  process.env.REACT_APP_ENV === "production"
    ? "https://api.quantking.net/api/v1/user/"
    : "https://api-dev.quantking.net/api/v1/user/";

const COMMON_URL =
  process.env.REACT_APP_ENV === "production"
    ? "https://api.quantking.net/api/v1/common/"
    : "https://api-dev.quantking.net/api/v1/common/";

export { BASE_URL, COMMON_URL };
