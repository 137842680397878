import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import TextEllipsis from "react-text-ellipsis";
import Util from "../../util";
import { useTranslation } from "react-i18next";

export const TablePoint = (props) => {
  const { t } = useTranslation(); // useTranslation 훅을 사용하여 t 함수를 가져옴

  const history = useHistory();
  const { type, data, loading, rowClick } = props;

  const buildContainerClassname = () => {
    let classname = "table-point";

    if (type) {
      classname += " " + type;
    }
    return classname;
  };

  console.log("Board", data);

  const renderType = (data) => {
    switch (data.type) {
      case "PaymentEarning": {
        return t("table.point.data.PaymentEarning");
      }
      case "PaymentPoints": {
        return t("table.point.data.PaymentPoints");
      }
      case "TransferFrom": {
        return t("table.point.data.TransferFrom");
      }
      case "TransferTo": {
        return t("table.point.data.TransferTo");
      }
      case "Calculate": {
        return t("table.point.data.Calculate");
      }
      default: {
        return "none";
      }
    }
  };

  const renderAmount = (data) => {
    // {Util.Form.NumberFormatter(item.amount)}
    switch (data.type) {
      case "PaymentEarning": {
        return Util.Form.formatNumber(data.amount) + "(+)";
      }
      case "PaymentPoints": {
        return Util.Form.formatNumber(data.amount) + "(-)";
      }
      case "TransferFrom": {
        return Util.Form.formatNumber(data.amount) + "(-)";
      }
      case "TransferTo": {
        return Util.Form.formatNumber(data.amount) + "(+)";
      }
      case "Calculate": {
        return Util.Form.formatNumber(data.amount) + "(-)";
      }
      default: {
        return "none";
      }
    }
  };

  if (loading) {
    return (
      <article className={buildContainerClassname()}>
        <div className="loader big color" />
      </article>
    );
  }

  if (data && data.length > 0) {
    return (
      <article className={buildContainerClassname()}>
        <ul className="table-header">
          <li>
            <span>{t("table.point.header.type")}</span>
          </li>
          <li>
            <span>{t("table.point.header.price")}</span>
          </li>
          <li>
            <span>{t("table.point.header.from")}</span>
          </li>
          <li>
            <span>{t("table.point.header.to")}</span>
          </li>
          <li>
            <span>{t("table.point.header.date")}</span>
          </li>
        </ul>
        {data.map((item, index) => {
          return (
            <ul
              className={"table-data " + item.type}
              key={"board-data-" + index}
            >
              <li>
                <span>{renderType(item)}</span>
              </li>
              <li>{renderAmount(item)}</li>
              <li>
                <span>{item.from}</span>
              </li>
              <li>
                <span>{item.to}</span>
              </li>
              <li>
                <span>{Util.Form.getYYYYMMDDByUnix(item.createdAt)}</span>
              </li>
            </ul>
          );
        })}
      </article>
    );
  } else if (data && data.length === 0) {
    return (
      <article className={buildContainerClassname()}>
        {
          <div className="empty">
            <span>{t("system.label.no_result")}</span>
          </div>
        }
      </article>
    );
  } else {
    return (
      <article className={buildContainerClassname()}>
        <div className="loader big color" />
      </article>
    );
  }
};
