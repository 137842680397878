export const SetItem = (key, value) => {
  if (typeof window !== "undefined") {
    // Perform localStorage action
    localStorage.setItem(key, value);
  }
};

export const GetItem = (key) => {
  if (typeof window !== "undefined") {
    // Perform localStorage action
    return localStorage.getItem(key);
  }
};

const Storage = {
  SetItem: SetItem,
  GetItem: GetItem,
};

export default Storage;
