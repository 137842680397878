import React, { useState, useEffect } from "react";
import { SnackbarContext } from "../contexts";

export const SnackbarProvider = (props) => {
  const { children } = props;
  const [snackbar, setSnackbar] = useState({
    type: null,
    open: false,
    message: "",
  });

  const updateSnackbar = (value) => {
    setSnackbar(value);
  };

  return (
    <SnackbarContext.Provider
      value={{
        snackbar: snackbar,
        updateSnackbar: updateSnackbar,
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};
