import { BASE_URL } from "../Config";
import AxiosInstance from "../AxiosInstance";
import Util from "../../util";

const PATH = "board/";

const GetNotice = (params) => {
  const url = BASE_URL + PATH + "notice" + Util.Form.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetNotcieDetail = (slug) => {
  const url = BASE_URL + PATH + "notice/" + slug;
  return AxiosInstance.get(url);
};

const GetMembership = (params) => {
  const url = BASE_URL + PATH + "membership" + Util.Form.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetMembershipDetail = (slug) => {
  const url = BASE_URL + PATH + "membership/" + slug;
  return AxiosInstance.get(url);
};

const GetFreeBoard = (params) => {
  const url = BASE_URL + PATH + "free" + Util.Form.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetGetFreeBoardDetail = (slug) => {
  const url = BASE_URL + PATH + "free/" + slug;
  return AxiosInstance.get(url);
};

const PostFreeBoard = (body) => {
  const url = BASE_URL + PATH + "free";
  return AxiosInstance.post(url, body);
};

const DeleteFreeBoard = (slug) => {
  const url = BASE_URL + PATH + "free/" + slug;
  return AxiosInstance.delete(url);
};

const Board = {
  GetNotice: GetNotice,
  GetNotcieDetail: GetNotcieDetail,
  GetMembership: GetMembership,
  GetMembershipDetail: GetMembershipDetail,
  GetFreeBoard: GetFreeBoard,
  GetGetFreeBoardDetail: GetGetFreeBoardDetail,
  PostFreeBoard: PostFreeBoard,
  DeleteFreeBoard: DeleteFreeBoard,
};

export default Board;
