import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { MainLayout, LoginLayout, MypageLayout, TermsLayout } from "./layouts";
import {
  HomePage,
  LoginPage,
  ProtectPage,
  UserSignupPage,
  UserFindPwPage,
  MypageInfoPage,
  NoticeListPage,
  NoticeDetailPage,
  MembershipListPage,
  MembershipDetailPage,
  BoardDetailPage,
  BoardListPage,
  BoardAddPage,
  UserResetPwPage,
  TermsPage,
  AboutPage,
  MembershipPage,
  MypageQnaListPage,
  MypageQnaAddPage,
  MypageQnaDetailPage,
  MypageMembershipPage,
  MypagePointPage,
  MypageCalculatePage,
  DownloadsPage,
} from "./pages";
import { UserContext } from "./contexts";

const ROUTER_LIST = [
  {
    path: "/",
    Component: HomePage,
    Layout: MainLayout,
    props: {
      exact: true,
    },
  },
  {
    path: "/login",
    Layout: LoginLayout,
    Component: LoginPage,
  },
  {
    path: "/user/find_pw",
    Layout: LoginLayout,
    Component: UserFindPwPage,
  },
  {
    path: "/user/reset_pw",
    Layout: LoginLayout,
    Component: UserResetPwPage,
  },
  // {
  //   path: "/user/reset_pw",
  //   Layout: LoginLayout,
  //   Component: UserResetPwPage,
  //   protect: true,
  // },
  {
    path: "/user/signup",
    Layout: LoginLayout,
    Component: UserSignupPage,
  },
  {
    path: "/mypage/info",
    Layout: MypageLayout,
    Component: MypageInfoPage,
    protect: true,
  },
  {
    path: "/mypage/qna/list",
    Layout: MypageLayout,
    Component: MypageQnaListPage,
    protect: true,
  },
  {
    path: "/mypage/qna/add",
    Layout: MypageLayout,
    Component: MypageQnaAddPage,
    protect: true,
  },
  {
    path: "/mypage/qna/detail/:slug",
    Layout: MypageLayout,
    Component: MypageQnaDetailPage,
    protect: true,
  },
  {
    path: "/mypage/membership",
    Layout: MypageLayout,
    Component: MypageMembershipPage,
    protect: true,
  },
  {
    path: "/mypage/point",
    Layout: MypageLayout,
    Component: MypagePointPage,
    protect: true,
  },
  {
    path: "/mypage/calculate",
    Layout: MypageLayout,
    Component: MypageCalculatePage,
    protect: true,
  },
  {
    path: "/notice/list",
    Layout: MainLayout,
    Component: NoticeListPage,
  },
  {
    path: "/notice/detail/:slug",
    Layout: MainLayout,
    Component: NoticeDetailPage,
  },
  {
    path: "/membership/list",
    Layout: MainLayout,
    Component: MembershipListPage,
  },
  {
    path: "/membership/detail/:slug",
    Layout: MainLayout,
    Component: MembershipDetailPage,
    protect: true,
  },
  {
    path: "/board/list",
    Layout: MainLayout,
    Component: BoardListPage,
  },
  {
    path: "/board/detail/:slug",
    Layout: MainLayout,
    Component: BoardDetailPage,
  },
  {
    path: "/board/add",
    Layout: MainLayout,
    Component: BoardAddPage,
    protect: true,
  },
  {
    path: "/downloads",
    Layout: MainLayout,
    Component: DownloadsPage,
  },
  {
    path: "/about",
    Layout: MainLayout,
    Component: AboutPage,
  },
  {
    path: "/membership",
    Layout: MainLayout,
    Component: MembershipPage,
  },
  {
    path: "/terms",
    Layout: TermsLayout,
    Component: TermsPage,
  },
  {
    path: "/protect",
    Layout: MainLayout,
    Component: ProtectPage,
    protect: true,
  },
];

const Routers = () => {
  return (
    <Router>
      <Switch>
        {ROUTER_LIST.map((item, index) => {
          const { Layout, Component, path, protect, props } = item;
          if (protect) {
            return (
              <PrivateRoute key={"route-" + index} path={path} {...props}>
                <Layout>
                  <Component></Component>
                </Layout>
              </PrivateRoute>
            );
          } else {
            return (
              <Route key={"route-" + index} path={path} {...props}>
                <Layout>
                  <Component></Component>
                </Layout>
              </Route>
            );
          }
        })}
      </Switch>
    </Router>
  );
};

function PrivateRoute({ children, ...rest }) {
  const { token } = useContext(UserContext);
  console.log("PrivateRoute", token);

  //token validation;
  let isValidation = false;
  if (token) {
    isValidation = true;
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isValidation ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default Routers;
