import React, { useEffect, useContext } from "react";
import { FormList, HomeBanner } from "../components";
import API from "../api";
import { SnackbarContext, UserContext } from "../contexts";
import { useState } from "react";
import program_intro_1 from "../styles/images/program_intro_1.png";
import program_intro_2 from "../styles/images/program_intro_2.png";
import program_intro_3 from "../styles/images/program_intro_3.png";
import program_intro_4 from "../styles/images/program_intro_4.png";
import program_intro_5 from "../styles/images/program_intro_5.png";
import program_intro_6 from "../styles/images/program_intro_6.png";
import program_intro_7 from "../styles/images/program_intro_7.png";
import program_intro_8 from "../styles/images/program_intro_8.png";
import program_intro_9 from "../styles/images/program_intro_9.png";
import program_intro_10 from "../styles/images/program_intro_10.png";
import program_intro_11 from "../styles/images/program_intro_11.png";
import program_intro_12 from "../styles/images/program_intro_12.png";
import program_intro_13 from "../styles/images/program_intro_13.png";
import program_intro_14 from "../styles/images/program_intro_14.png";
import program_intro_15 from "../styles/images/program_intro_15.png";
import program_intro_16 from "../styles/images/program_intro_16.png";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export const HomePage = (props) => {
  const history = useHistory();
  const { t } = useTranslation(); // useTranslation 훅을 사용하여 t 함수를 가져옴
  const { downloadLink } = useContext(UserContext);
  const { updateSnackbar } = useContext(SnackbarContext);
  const [chartOriginData, setChartOriginData] = useState(null);
  const [chartType, setChartType] = useState("ACCUMULATE"); //YEAR, MONTH, ACCUMULATE
  const [chartData, setChartData] = useState([]);
  const [selectedIntroPrgoram, setSelectedIntroPrgoram] = useState(1);
  // const INTRO_PROGRAM_LIST = [
  //   { id: 1, text: t("home.intro_img.label.1") },
  //   { id: 2, text: "경제지표(표)" },
  //   { id: 3, text: "종목 비교" },
  //   { id: 4, text: "종목 현황" },
  //   { id: 5, text: "재무차트" },
  //   { id: 6, text: "분기실적" },
  //   { id: 7, text: "퀀트 데이터" },
  //   { id: 8, text: "퀀트 포트 종목" },
  //   { id: 9, text: "퀀트 포트 수익률" },
  //   { id: 10, text: "나만의 종목" },
  //   { id: 11, text: "백테스트" },
  //   { id: 12, text: "종목 검색 순위" },
  //   { id: 13, text: "설정" },
  //   { id: 14, text: "매매 계산기" },
  //   { id: 15, text: "자동매매" },
  // ];
  const INTRO_PROGRAM_LIST = [
    {
      id: 1,
      text: t("home.intro_img.label.1"),
    },
    {
      id: 2,
      text: t("home.intro_img.label.2"),
    },
    {
      id: 3,
      text: t("home.intro_img.label.3"),
    },
    {
      id: 4,
      text: t("home.intro_img.label.4"),
    },
    {
      id: 5,
      text: t("home.intro_img.label.5"),
    },
    {
      id: 6,
      text: t("home.intro_img.label.6"),
    },
    {
      id: 7,
      text: t("home.intro_img.label.7"),
    },
    {
      id: 8,
      text: t("home.intro_img.label.8"),
    },
    {
      id: 9,
      text: t("home.intro_img.label.9"),
    },
    {
      id: 10,
      text: t("home.intro_img.label.10"),
    },
    {
      id: 11,
      text: t("home.intro_img.label.11"),
    },
    {
      id: 12,
      text: t("home.intro_img.label.12"),
    },
    {
      id: 13,
      text: t("home.intro_img.label.13"),
    },
    {
      id: 14,
      text: t("home.intro_img.label.14"),
    },
    {
      id: 15,
      text: t("home.intro_img.label.15"),
    },
    {
      id: 16,
      text: t("home.intro_img.label.16"),
    },
  ];
  const [selectedYoutubeId, setSelectedYoutubeId] = useState(1);
  // const YOUTUBE_LIST = [
  //   { id: 1, name: "경제지표" },
  //   { id: 2, name: "종목현황과 분기실적" },
  //   { id: 3, name: "퀀트데이터" },
  //   { id: 4, name: "퀀트 포트 + 나만의 종목" },
  //   { id: 5, name: "백테스트" },
  // ];
  const YOUTUBE_LIST = [
    { id: 1, name: t("home.youtube.label.1") },
    { id: 2, name: t("home.youtube.label.2") },
    { id: 3, name: t("home.youtube.label.3") },
    { id: 4, name: t("home.youtube.label.4") },
    { id: 5, name: t("home.youtube.label.5") },
  ];
  var userLang = navigator.language || navigator.userLanguage;

  console.log("userLang", userLang);
  useEffect(() => {
    let params = {
      serviceRegion: "domestic",
    };
    API.Home.GetChart(params).then(
      (res) => {
        console.log("GetChart", res);
        setChartOriginData(res.data.domestic);
      },
      (err) => {
        console.log("err", err);
        updateSnackbar({
          type: "error",
          open: true,
          message: err.data.message,
        });
      }
    );
  }, []);

  console.log("chartOriginData", chartOriginData);

  useEffect(() => {
    if (chartOriginData) {
      // YEAR, MONTH, ACCUMULATE
      switch (chartType) {
        case "YEAR": {
          setChartData(chartOriginData.year);
          break;
        }
        case "MONTH": {
          setChartData(chartOriginData.month);
          break;
        }
        case "ACCUMULATE": {
          setChartData(chartOriginData.accumulate);
          break;
        }
      }
    }
  }, [chartOriginData, chartType]);

  const renderProgramImg = () => {
    switch (selectedIntroPrgoram) {
      case 1: {
        return <img src={program_intro_1}></img>;
      }
      case 2: {
        return <img src={program_intro_2}></img>;
      }
      case 3: {
        return <img src={program_intro_3}></img>;
      }
      case 4: {
        return <img src={program_intro_4}></img>;
      }
      case 5: {
        return <img src={program_intro_5}></img>;
      }
      case 6: {
        return <img src={program_intro_6}></img>;
      }
      case 7: {
        return <img src={program_intro_7}></img>;
      }
      case 8: {
        return <img src={program_intro_8}></img>;
      }
      case 9: {
        return <img src={program_intro_9}></img>;
      }
      case 10: {
        return <img src={program_intro_10}></img>;
      }
      case 11: {
        return <img src={program_intro_11}></img>;
      }
      case 12: {
        return <img src={program_intro_12}></img>;
      }
      case 13: {
        return <img src={program_intro_13}></img>;
      }
      case 14: {
        return <img src={program_intro_14}></img>;
      }
      case 15: {
        return <img src={program_intro_15}></img>;
      }
      case 16: {
        return <img src={program_intro_16}></img>;
      }
      default:
        return <span>none</span>;
    }
  };

  const getYoutubeSrc = () => {
    switch (selectedYoutubeId) {
      case 1: {
        return "https://www.youtube.com/embed/fNb3gDpsIgA";
      }
      case 2: {
        return "https://www.youtube.com/embed/FrPu7Gh237Y";
      }
      case 3: {
        return "https://www.youtube.com/embed/Ix2iNMpIQwE";
      }
      case 4: {
        return "https://www.youtube.com/embed/BgWlzJ0s47Y";
      }
      case 5: {
        return "https://www.youtube.com/embed/5-Am0o8WT2U";
      }
      default: {
        return "none";
      }
    }
  };
  return (
    <article className="home-page">
      <div className="full__wrapper">
        {/* <div className="bg-top-line__box"></div> */}
        <HomeBanner
          chartData={chartData}
          chartType={chartType}
          onChangeChartType={setChartType}
        ></HomeBanner>
      </div>
      <div className="full__wrapper center">
        <div className="content__wrapper">
          <section className="naver-cafe__container">
            <div className="caption__box">
              <h2>{t("home.program.title")}</h2>
              <h4>{t("home.program.desc")}</h4>
            </div>
            <div className="img__box"></div>
          </section>
        </div>
      </div>

      <div className="full__wrapper center" style={{ background: "#FD601C" }}>
        <div className="content__wrapper">
          <section className="benefit__container">
            <header>
              <h1>{t("home.benefit.title")}</h1>
            </header>
            <ul>
              <li>
                <h2>
                  글로벌 퀀트의 <b>무제한 백테스트 제공</b> (올인원 275불,
                  국가별 각 175불)
                </h2>
                <p>{t("home.benefit.1.desc")}</p>
              </li>
              <li>
                <h2>
                  실적발표및 재무데이터 <b>수작업 추가반영</b>
                </h2>
                <p>{t("home.benefit.2.desc")}</p>
              </li>
              <li>
                <h2>
                  고객 맞춤형{" "}
                  <b>
                    경제지표 + 퀀트데이터 + 나만의차트 + 백테스트 나만의 항목
                  </b>{" "}
                  제공 (한국최초)
                </h2>
                <h2></h2>
                <p>{t("home.benefit.3.desc")}</p>
              </li>
              <li>
                <h2>
                  백테스트내 경제지표의{" "}
                  <b>헷징설정의 진입과 청산 시기를 선택가능.</b> (한국 최초)
                </h2>
                <p>{t("home.benefit.4.desc")}</p>
              </li>
              <li>
                <h2>
                  백테스트내 상폐반영을 <b>나쁜상폐와 좋은 상폐로 구분</b>해서
                  제공.(한국 최초)
                </h2>
                <p>{t("home.benefit.5.desc")}</p>
              </li>
              <li>
                <h2>
                  백테스트내 <b>자산배분 기능 적용.</b>
                </h2>
                <p>{t("home.benefit.6.desc")}</p>
              </li>
            </ul>
          </section>
        </div>
      </div>

      <div className="full__wrapper center">
        <div className="content__wrapper">
          <div className="menual__container">
            <header>
              <h1>{t("home.intro_img.title")}</h1>
            </header>
            <div className="guide__box">
              <ul>
                {INTRO_PROGRAM_LIST.map((item) => (
                  <li
                    onClick={() => {
                      setSelectedIntroPrgoram(item.id);
                    }}
                    key={item.id}
                    className={item.id === selectedIntroPrgoram ? "on" : ""}
                  >
                    <span>{item.text}</span>
                  </li>
                ))}
              </ul>
            </div>

            <section className="img__box">{renderProgramImg()}</section>
          </div>
        </div>
      </div>

      <div className="full__wrapper center">
        <div className="content__wrapper">
          <div className="how-to-use__container">
            <header>
              <h1>{t("home.youtube.title")}</h1>
            </header>
            <div className="guide__box">
              <ul>
                {YOUTUBE_LIST.map((item) => (
                  <li
                    onClick={() => {
                      setSelectedYoutubeId(item.id);
                    }}
                    key={item.id}
                    className={item.id === selectedYoutubeId ? "on" : ""}
                  >
                    <span>{item.name}</span>
                  </li>
                ))}
              </ul>
            </div>
            <section className="youtube__box">
              <div className="ratio__box">
                <div className="iframe__box">
                  <iframe
                    width="100%"
                    height="100%"
                    src={getYoutubeSrc()}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

      <div className="full__wrapper center">
        <div className="download__container">
          <section className="left__box">
            <header>
              <h1>{t("home.prgoram_download.title")}</h1>
              <p>{t("home.prgoram_download.sub_title")}</p>
            </header>
            <div className="button__box">
              {/* <button
                onClick={() => {
                  window.open(downloadLink.domestic, "_blank");
                }}
              >
                {t("home.prgoram_download.label_kr")}
              </button>
              <button
                onClick={() => {
                  window.open(downloadLink.usa, "_blank");
                }}
              >
                {t("home.prgoram_download.label_en")}
              </button> */}
              <button
                onClick={() => {
                  history.push("/downloads");
                }}
              >
                프로그램 다운로드
              </button>
            </div>
          </section>
          <section className="right__box">
            <header>
              <h1>{t("home.menual_download.title")}</h1>
              <p>{t("home.menual_download.sub_title")}</p>
            </header>
            <button
              onClick={() => {
                fetch("https://storagequantking.blob.core.windows.net/quantkingfile/QuantkingManual_korea_v1.pdf", {
                  method: "GET",
                  responseType: "blob", // Blob 형태로 응답 받기
                })
                  .then((response) => response.blob())
                  .then((blob) => {
                    // Blob 객체로부터 다운로드 링크 생성
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "quantking_menual.pdf");
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                  })
                  .catch((error) => {
                    console.error("PDF 다운로드에 실패했습니다:", error);
                  });
              }}
            >
              {t("home.menual_download.label_kr")}
            </button>
          </section>
        </div>
      </div>

      <div
        className="full__wrapper center"
        style={{ position: "relative", background: "#000000" }}
      >
        <div className="bg__item"></div>
        <div className="content__wrapper">
          <div className="ment__container">
            <section className="caption__box">
              <h3>{t("home.ment.desc")}</h3>
              <h1>{t("home.ment.title")}</h1>
            </section>
          </div>
        </div>
      </div>
    </article>
  );
};
