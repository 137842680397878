import { BASE_URL } from "../Config";
import AxiosInstance from "../AxiosInstance";
import Util from "../../util";

const PATH = "home/";

const GetChart = (params) => {
  const url = BASE_URL + PATH + "chart" + Util.Form.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const Home = {
  GetChart: GetChart,
};

export default Home;
