import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  CartesianAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useTranslation } from "react-i18next";

const sample = {
  year: [
    {
      date: "2012",
      magic: 24.6,
      low: 42.1,
      growth: 3.8,
      quant: 52.4,
      cospi: 9.4,
      kosdaq: -0.8,
    },
    {
      date: "2013",
      magic: 24.6,
      low: 42.1,
      growth: 3.8,
      quant: 52.4,
      cospi: 9.4,
      kosdaq: -0.8,
    },
    {
      date: "2014",
      magic: 24.6,
      low: 42.1,
      growth: 3.8,
      quant: 52.4,
      cospi: 9.4,
      kosdaq: -0.8,
    },
  ],
  accum: [
    {
      date: "2012",
      magic: 24.6,
      low: 42.1,
      growth: 3.8,
      quant: 52.4,
      cospi: 9.4,
      kosdaq: -0.8,
    },
    {
      date: "2013",
      magic: 24.6,
      low: 42.1,
      growth: 3.8,
      quant: 52.4,
      cospi: 9.4,
      kosdaq: -0.8,
    },
    {
      date: "2014",
      magic: 24.6,
      low: 42.1,
      growth: 3.8,
      quant: 52.4,
      cospi: 9.4,
      kosdaq: -0.8,
    },
  ],
  month: [
    {
      date: "2012-01",
      magic: 24.6,
      low: 42.1,
      growth: 3.8,
      quant: 52.4,
      cospi: 9.4,
      kosdaq: -0.8,
    },
    {
      date: "2012-02",
      magic: 24.6,
      low: 42.1,
      growth: 3.8,
      quant: 52.4,
      cospi: 9.4,
      kosdaq: -0.8,
    },
    {
      date: "2012-03",
      magic: 24.6,
      low: 42.1,
      growth: 3.8,
      quant: 52.4,
      cospi: 9.4,
      kosdaq: -0.8,
    },
  ],
};

export const HomeBanner = (props) => {
  const { t } = useTranslation(); // useTranslation 훅을 사용하여 t 함수를 가져옴
  const history = useHistory();
  const { chartType, chartData, onChangeChartType } = props;

  const CHART_TYPE_LIST = [
    {
      value: "MONTH",
      label: t("home.chart.type.month"),
    },
    {
      value: "YEAR",
      label: t("home.chart.type.year"),
    },
    {
      value: "ACCUMULATE",
      label: t("home.chart.type.accumulate"),
    },
  ];
  const buildContainerClassname = () => {
    let classname = "home-banner";

    return classname;
  };

  const renderCustomTick = (props) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={24} textAnchor="middle" fill="#999999">
          {/* 라벨 값과 차트 값 사이의 간격 설정 */}
          <tspan>{payload.value}</tspan>
          <tspan x={0} dy={24} fontSize="12" fill="#999999">
            {payload.amt}
          </tspan>
        </text>
      </g>
    );
  };

  return (
    <article className={buildContainerClassname()}>
      <div className="banner__wrapper">
        <section className="text__box">
          {/* <h2>왜 퀀트투자일까요?</h2> */}
          {/* <p>풍요로운 노후를 즐기고 싶으신가요?</p>
          <p>현재의 인생을 즐기고 싶으신가요?</p>
          <p>돈을 가장 효율적으로 불릴 수 있는 방법은 있을까요?</p> */}
          {/* <h1>
            돈을 가장 가성비 좋게 효율적으로
            <br /> 불릴 수 있는 방법이 있을까요?
          </h1>
          <p>
            퀀트는 숫자에 기반한 수학통계적 기법으로 투자하며, 사람의 판단이
            개입되지 않습니다. <br /> 보통 우리가 지금까지 주식투자로 실패했던
            이유는 바로 사람의 판단이 들어갔기 때문입니다. <br /> 수학통계적
            기법을 따르는 퀀트투자는 장기적으로 부자가 될 확률이 가장 높은
            투자법입니다. 퀀트킹과 함께 투자하면, 노후 준비는 물론이고, 현재의
            인생도 즐길 수 있습니다.
            <br /> 퀀트킹이 이상을 현실로 만드는 첫걸음을 안전하게 이끌어
            드립니다.
          </p> */}
          <h1>{t("home.chart.title")}</h1>
          <p>{t("home.chart.desc")}</p>
          <h4>{t("home.chart.ment")}</h4>
        </section>
        <section className="chart__box">
          <header>
            <ul>
              {CHART_TYPE_LIST.map((item, index) => {
                return (
                  <li
                    onClick={() => {
                      onChangeChartType(item.value);
                    }}
                    key={"home-banner-chart-type-li-" + index}
                    className={item.value === chartType ? "on" : ""}
                  >
                    <span>{item.label}</span>
                  </li>
                );
              })}
            </ul>
          </header>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={chartData}
              margin={{
                top: 20,
                right: 40,
                left: 10,
                bottom: 20,
              }}
            >
              <XAxis
                tick={renderCustomTick}
                padding={{ top: 0, bttom: 20 }}
                dataKey="date"
                stroke="#999999"
              />
              <YAxis unit="%" stroke="#999999" />
              <Tooltip
                formatter={(value, name) => {
                  let label = "";
                  switch (name) {
                    case "magic": {
                      label = t("home.chart.label.magic");
                      break;
                    }
                    case "low": {
                      label = t("home.chart.label.low");
                      break;
                    }
                    case "growth": {
                      label = t("home.chart.label.growth");
                      break;
                    }
                    case "sp500": {
                      label = t("home.chart.label.sp500");
                      break;
                    }
                    case "kosdaq": {
                      label = t("home.chart.label.kosdaq");
                      break;
                    }
                    case "cospi": {
                      label = t("home.chart.label.cospi");
                      break;
                    }
                    case "quant": {
                      label = t("home.chart.label.quant");
                      break;
                    }
                    default: {
                      label = "none";
                    }
                  }

                  return [value + "%", label];
                }}
              />
              <Legend
                verticalAlign="top"
                content={(props) => {
                  const { payload } = props;
                  const getLabelText = (value) => {
                    switch (value) {
                      case "magic": {
                        return t("home.chart.label.magic");
                      }
                      case "low": {
                        return t("home.chart.label.low");
                      }
                      case "growth": {
                        return t("home.chart.label.growth");
                      }
                      case "sp500": {
                        return t("home.chart.label.sp500");
                      }
                      case "kosdaq": {
                        return t("home.chart.label.kosdaq");
                      }
                      case "cospi": {
                        return t("home.chart.label.cospi");
                      }
                      case "quant": {
                        return t("home.chart.label.quant");
                      }
                      default: {
                        return <span>{value}</span>;
                      }
                    }
                  };
                  return (
                    <ul className="banner-chart__legend">
                      {payload.map((entry, index) => (
                        <li key={`item-${index}`}>
                          <div
                            style={{ backgroundColor: entry.color }}
                            className="circle"
                          ></div>
                          <span style={{ color: entry.color }}>
                            {getLabelText(entry.value)}
                          </span>
                        </li>
                      ))}
                    </ul>
                  );
                }}
              />
              <Line
                strokeDasharray="5 5"
                type="monotone"
                dataKey="cospi"
                stroke="#000000"
                aactiveDot={{ r: 4 }}
                dot={false}
              />
              <Line
                strokeDasharray="5 5"
                type="monotone"
                dataKey="kosdaq"
                stroke="#008000"
                aactiveDot={{ r: 4 }}
                dot={false}
              />
              <Line
                strokeWidth={2}
                type="monotone"
                dataKey="magic"
                stroke="#0000FF"
                activeDot={{ r: 4 }}
                dot={false}
              />
              <Line
                strokeWidth={2}
                type="monotone"
                dataKey="low"
                stroke="#FFCC00"
                aactiveDot={{ r: 4 }}
                dot={false}
              />

              <Line
                type="monotone"
                dataKey="quant"
                stroke="#FF8C00"
                strokeWidth={2}
                aactiveDot={{ r: 4 }}
                dot={false}
              />
              <Line
                strokeWidth={2}
                type="monotone"
                dataKey="growth"
                stroke="#FF0000"
                aactiveDot={{ r: 4 }}
                dot={false}
              />
              <CartesianGrid strokeDasharray="3 3" />
            </LineChart>
          </ResponsiveContainer>
        </section>
      </div>
    </article>
  );
};
