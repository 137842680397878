import { BASE_URL } from "../Config";
import AxiosInstance from "../AxiosInstance";
import Util from "../../util";

const PATH = "payment/";

const GetPrice = () => {
  const url = BASE_URL + PATH + "price";
  return AxiosInstance.get(url);
};

const GetStripeUrl = (params) => {
  const url = BASE_URL + PATH + "stripe" + Util.Form.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const PostPoint = (body) => {
  const url = BASE_URL + PATH + "point";
  return AxiosInstance.post(url, body);
};

const GetPriceInfo = () => {
  const url = BASE_URL + PATH + "price/info";
  return AxiosInstance.get(url);
};

const Payment = {
  GetPrice: GetPrice,
  GetStripeUrl: GetStripeUrl,
  PostPoint: PostPoint,
  GetPriceInfo: GetPriceInfo,
};

export default Payment;
