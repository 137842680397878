import { BASE_URL } from "../Config";
import AxiosInstance from "../AxiosInstance";

const PATH = "terms/";

const Get = () => {
  const url = BASE_URL + PATH;
  return AxiosInstance.get(url);
};

const Terms = {
  Get: Get,
};

export default Terms;
