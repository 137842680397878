import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserContext, SnackbarContext } from "../../contexts";
import { useInput, usePagination } from "../../hooks";
import { FormInput, Button, Paging, Board, TableQna } from "../../components";
import API from "../../api";
import Util from "../../util";
import { useTranslation } from "react-i18next";

export const MypageQnaListPage = (props) => {
  const { t } = useTranslation(); // useTranslation 훅을 사용하여 t 함수를 가져옴
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { userInfo, refreshUserInfo } = useContext(UserContext);
  const [list, setList] = useState(null);
  const [
    page,
    totalPage,
    totalCount,
    onPageChange,
    setPage,
    setTotalPage,
    setTotalCount,
  ] = usePagination(1, 10);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    requsetList();
  }, [page]);

  const requsetList = (init_page) => {
    setLoading(true);
    let params = {
      page: init_page ? init_page : page,
      limit: 10,
    };

    API.Member.GetInquiry(params).then(
      (res) => {
        let result = res.data;
        console.log("Board.GetNoticeresult", result);
        setList(result.items);
        setTotalPage(result.meta.totalPages);
        setTotalCount(result.meta.totalCount);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        updateSnackbar({
          type: "error",
          open: true,
          message: err.data.message,
        });
      }
    );
  };

  const onRowClick = (index) => {
    console.log("onRowClick", index);

    let target = list[index];
    history.push("/mypage/qna/detail/" + target.no);
  };

  return (
    <article className="mypage-qna-list-page">
      <section className="title__box">
        <h3>{t("mypage.qna_list.title")}</h3>
        <div className="button__box">
          <Button
            label={t("mypage.qna_list.button")}
            onClick={() => {
              history.push("/mypage/qna/add");
            }}
          ></Button>
        </div>
      </section>

      <section className="table__box">
        <TableQna
          data={list}
          loading={loading}
          rowClick={onRowClick}
        ></TableQna>
      </section>

      <section className="page__box">
        <Paging
          page={page}
          totalPage={totalPage}
          onChange={onPageChange}
        ></Paging>
      </section>
    </article>
  );
};
