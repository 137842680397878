import React, { useEffect, useContext } from "react";
import { UserContext } from "../contexts";

export const AboutPage = (props) => {
  const { clearToken } = useContext(UserContext);

  return (
    <article className="about-page">
      <span>about</span>
    </article>
  );
};
