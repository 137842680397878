const AUTH_TEXT = {
  AdminUser: "관리자",
  LaunchPad: "INO",
  Market: "마켓",
  Banner: "배너",
  User: "유저",
  Game: "게임",
  Account: "계정",
  GamePayment: "INO 정산",
  MarketPayment: "마켓 정산",
  Token: "게임 토큰",
  Swap: "스왑",
  Collection: "콜렉션",
  ZemX: "ZEMX 벌크 전송",
  Transfer: "Transfer 목록",
};

const Variables = {
  AUTH_TEXT: AUTH_TEXT,
};

export default Variables;
