import React, { useEffect, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { UserContext, SnackbarContext } from "../../contexts";
import { useInput, usePagination } from "../../hooks";
import { FormInput, Button, Paging, Board } from "../../components";
import API from "../../api";
import Util from "../../util";
import { useTranslation } from "react-i18next";
import queryString from "query-string";

export const MembershipListPage = (props) => {
  const { t } = useTranslation(); // useTranslation 훅을 사용하여 t 함수를 가져옴
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { userInfo } = useContext(UserContext);
  const [list, setList] = useState(null);
  const searchObject = queryString.parse(history.location.search);
  const initPage =
    searchObject && searchObject.page ? Number(searchObject.page) : 1;
  const [
    page,
    totalPage,
    totalCount,
    onPageChange,
    setPage,
    setTotalPage,
    setTotalCount,
  ] = usePagination(initPage, 10);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    history.push("/membership/list?page=" + page);
    requsetList();
  }, [page]);

  const requsetList = (init_page) => {
    setLoading(true);
    let params = {
      page: init_page ? init_page : page,
      limit: 10,
    };

    API.Board.GetMembership(params).then(
      (res) => {
        let result = res.data;
        console.log("Board.GetNoticeresult", result);
        setList(result.items);
        setTotalPage(result.meta.totalPages);
        setTotalCount(result.meta.totalCount);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        updateSnackbar({
          type: "error",
          open: true,
          message: err.data.message,
        });
      }
    );
  };

  const onRowClick = (index) => {
    console.log("onRowClick", Date.now());

    if (userInfo) {
      if (
        Util.Form.isVipDateGreaterThanNow(userInfo.vipDate, userInfo.vipDateUsa)
      ) {
        let target = list[index];
        history.push("/membership/detail/" + target.no);
      } else {
        updateSnackbar({
          type: "error",
          open: true,
          message: t("system.alert.membership_only"),
        });
      }
    } else {
      updateSnackbar({
        type: "error",
        open: true,
        message: t("system.alert.no_login"),
      });
      history.push("/login");
    }
  };

  return (
    <article className="board-list-page">
      <div className="board__wrapper">
        <section className="title__box">
          <h3>{t("membership_board.title")}</h3>
        </section>

        <section className="board__box">
          <Board
            type={"membership"}
            data={list}
            loading={loading}
            rowClick={onRowClick}
          ></Board>
        </section>

        <section className="page__box">
          <Paging
            page={page}
            totalPage={totalPage}
            onChange={onPageChange}
          ></Paging>
        </section>
      </div>
    </article>
  );
};
