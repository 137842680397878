import React, { useState, useEffect, useContext } from "react";
import { UserContext, SnackbarContext } from "../contexts";
import Util from "../util";
import API from "../api";

export const UserProvider = (props) => {
  const { children } = props;
  const [token, setToken] = useState(Util.Storage.GetItem("token"));
  const { updateSnackbar } = useContext(SnackbarContext);
  const [userInfo, setUserInfo] = useState(null);
  const [downloadLink, setDownloadLink] = useState(null);

  console.log("UserProvider - token", token);
  console.log("UserProvider - storage", Util.Storage.GetItem("token"));

  // useEffect(() => {
  //   API.Common.GetProgramLink().then(
  //     (res) => {
  //       console.log("GetProgramLink", res);
  //       setDownloadLink(res.data);
  //     },
  //     (err) => {
  //       console.log("err", err);
  //     }
  //   );
  // }, []);

  const clearToken = () => {
    Util.Storage.SetItem("token", "");
    setToken(null);
    setUserInfo(null);
  };

  const updateToken = (toeknValue) => {
    Util.Storage.SetItem("token", toeknValue);
    setToken(toeknValue);
  };

  const refreshUserInfo = () => {
    API.Member.Get().then(
      (res) => {
        console.log("Member.Get()", res);
        setUserInfo(res.data);
      },
      (err) => {
        updateSnackbar({
          type: "error",
          open: true,
          message: err.data.message,
        });
      }
    );
  };

  useEffect(() => {
    console.log("UserProvider - token", token);
    //token validation
    if (token) {
      API.Member.Get().then(
        (res) => {
          console.log("Member.Get()", res);
          setUserInfo(res.data);
        },
        (err) => {
          updateSnackbar({
            type: "error",
            open: true,
            message: err.data.message,
          });
          clearToken();
        }
      );
    }
  }, [token]);

  return (
    <UserContext.Provider
      value={{
        token: token,
        updateToken: updateToken,
        clearToken: clearToken,
        userInfo: userInfo,
        refreshUserInfo: refreshUserInfo,
        downloadLink: downloadLink,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
