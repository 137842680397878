import React, { useEffect, useState, useContext, userEffect } from "react";
import { useHistory } from "react-router-dom";
import { UserContext, SnackbarContext, ModalContext } from "../../contexts";
import { useInput } from "../../hooks";
import { FormInput, Button } from "../../components";
import API from "../../api";
import Util from "../../util";
import { useTranslation } from "react-i18next";

export const MypageInfoPage = (props) => {
  const { t } = useTranslation(); // useTranslation 훅을 사용하여 t 함수를 가져옴
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { userInfo, refreshUserInfo, clearToken } = useContext(UserContext);
  const [form, onChangeForm, resetForm] = useInput({
    id: "",
    name: "",
    nick: "",
    phone: "",
  });
  const {
    modalData,
    updateModalOpen,
    updateModalData,
    closeModal,
  } = useContext(ModalContext);
  useEffect(() => {
    refreshUserInfo();
  }, []);

  useEffect(() => {
    if (userInfo) {
      resetForm({ ...userInfo });
    }
  }, [userInfo]);

  const requestSave = () => {
    console.log("requestSave");

    let body = {
      name: form.name,
      nick: form.nick,
      phone: form.phone,
    };
    API.Member.Put(body).then(
      (res) => {
        console.log("res", res);
        let result = res.data;
        console.log("result", result);
        //return 후 토큰을 주어야함
        updateSnackbar({
          type: "success",
          open: true,
          message: t("mypage.alert.info_success"),
        });
        refreshUserInfo();
      },
      (err) => {
        updateSnackbar({
          type: "error",
          open: true,
          message: err.data.message,
        });
      }
    );
  };
  console.log("userInfo", userInfo);
  return (
    <article className="mypage-info-page">
      <section className="title__box">
        <h3>{t("mypage.info.title")}</h3>
      </section>
      <section>
        <section className="input__box">
          <FormInput
            label={t("form.label.id")}
            value={form.id}
            name={"id"}
            onChange={onChangeForm}
            disabled={true}
          ></FormInput>
          <div className="pw__box">
            <header>
              <span>{t("form.label.password")}</span>
            </header>
            <b
              onClick={() => {
                history.push("/user/reset_pw");
              }}
            >
              {t("mypage.info.button.reset_pw")}
            </b>
          </div>
          <FormInput
            label={t("form.label.name")}
            value={form.name}
            name={"name"}
            onChange={onChangeForm}
          ></FormInput>
          <FormInput
            label={t("form.label.nick")}
            value={form.nick}
            name={"nick"}
            onChange={onChangeForm}
          ></FormInput>
          {/* <FormInput
            label={t("form.label.phone")}
            value={form.phone}
            name={"phone"}
            onChange={onChangeForm}
            placeholder={t("form.placeholder.phone")}
          ></FormInput> */}
          <div className="referral-viwer">
            <div className="label__box">
              <span>{t("form.label.referer_code")}</span>
            </div>
            <div className="value__box">
              <span
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  const el = document.createElement("textarea");
                  el.value = form.referralCode;
                  el.setAttribute("readonly", "");
                  el.style.position = "absolute";
                  el.style.left = "-9999px";
                  document.body.appendChild(el);
                  el.select();
                  document.execCommand("copy");
                  document.body.removeChild(el);
                  updateSnackbar({
                    type: "success",
                    open: true,
                    message: t("mypage.alert.referral_copy_success"),
                  });
                }}
              >
                {form.referralCode}
              </span>
            </div>
          </div>
          <div className="referral-viwer">
            <div className="label__box">
              <span>{t("form.label.referer_url")}</span>
            </div>
            <div className="value__box">
              <span
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  const el = document.createElement("textarea");
                  el.value = `${window.location.origin}/user/signup?code=${form.referralCode}`;
                  el.setAttribute("readonly", "");
                  el.style.position = "absolute";
                  el.style.left = "-9999px";
                  document.body.appendChild(el);
                  el.select();
                  document.execCommand("copy");
                  document.body.removeChild(el);
                  updateSnackbar({
                    type: "success",
                    open: true,
                    message: t("mypage.alert.referral_url_success"),
                  });
                }}
              >
                {window.location.origin}/user/signup?code={form.referralCode}
              </span>
            </div>
          </div>
          <div className="referral-viwer">
            <div className="label__box">
              <span
                className="secession"
                onClick={() => {
                  updateModalData({
                    type: "secession",
                    title: t("mypage.secession.title"),
                    content: t("mypage.secession.content"),
                    onConfirm: (reason) => {
                      console.log("work", reason);

                      API.Member.PostSecession({
                        reason: reason,
                      }).then(
                        (res) => {
                          updateSnackbar({
                            type: "success",
                            open: true,
                            message: "탈퇴 성공",
                          });
                          clearToken();
                        },
                        (err) => {
                          updateSnackbar({
                            type: "error",
                            open: true,
                            message: err.data.message,
                          });
                        }
                      );
                      updateModalData({
                        type: "",
                      });
                      updateModalOpen(false);
                    },
                  });
                  updateModalOpen(true);
                }}
              >
                {t("mypage.info.button.secession")}
              </span>
            </div>
          </div>
        </section>
      </section>
      <section className="button__box">
        <Button
          label={t("mypage.info.button.confirm")}
          onClick={requestSave}
        ></Button>
      </section>
    </article>
  );
};
