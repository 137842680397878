import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export const FormInput = (props) => {
  const {
    type,
    label,
    value,
    name,
    onChange,
    errorValidation,
    disabled,
    onEnter,
    placeholder,
    errorText,
  } = props;
  const [error, setError] = useState(false);

  const buildContainerClassname = () => {
    let classname = "form-input";

    if (error) {
      classname += " error";
    }

    if (disabled) {
      classname += " disabled";
    }

    return classname;
  };

  useEffect(() => {
    if (value && errorValidation) {
      let timer = setTimeout(() => {
        const validation_result = errorValidation(value);
        setError(!validation_result);
      }, 500);
      //useEffect의 return절은 clean up용도
      return () => {
        clearTimeout(timer);
      };
    } else {
      setError(false);
    }
  }, [value]);

  return (
    <article className={buildContainerClassname()}>
      {label && (
        <div className="label__box">
          <span>{label}</span>
        </div>
      )}
      <input
        disabled={disabled}
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={(e) => {
          onChange(e);
        }}
        onKeyUp={(e) => {
          if (e.keyCode === 13) {
            console.log("enter");
            if (onEnter) {
              onEnter();
            }
          }
        }}
      ></input>
      {error && errorText && (
        <section className="error-text__box">
          <span>{errorText}</span>
        </section>
      )}
    </article>
  );
};
