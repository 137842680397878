import axios from "axios";
import { BASE_URL } from "./Config";
import Util from "../util";

const AxiosInstance = axios.create({
  baseURL: BASE_URL,
  responseType: "json",
  headers: {
    "Content-Type": "application/json;charset=utf-8",
  },
});

// 요청 인터셉터 추가
AxiosInstance.interceptors.request.use(
  async (config) => {
    console.log("interceptors request config", config);

    let token = Util.Storage.GetItem("token");
    console.log("interceptors request config token", token);
    if (token) {
      config.headers.Authorization = "Bearer " + Util.Storage.GetItem("token");
    }

    return config;
  },
  (error) => {
    console.log("interceptors request error", error);

    return Promise.reject(error);
  }
);

// 응답 인터셉터 추가
AxiosInstance.interceptors.response.use(
  (response) => {
    console.log("interceptors response", response);
    return response;
  },
  (error) => {
    console.log("interceptors response error", error);
    return Promise.reject(error.response);
  }
);

export default AxiosInstance;
