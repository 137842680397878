// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en_translations from "./locales/en/translations.json";
import ko_translations from "./locales/ko/translations.json";

const browserLanguage = navigator.language;

console.log("browserLanguage", browserLanguage);

i18n.use(initReactI18next).init({
  debug: true,
  // lng: browserLanguage === "ko-KR" ? "kr" : "en",
  lng: "kr",
  fallbackLng: "kr",
  resources: {
    en: { translation: en_translations },
    kr: { translation: ko_translations },
  },
  react: {
    useSuspense: false,
  },
});

export default i18n;
