import React, { useEffect, useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { UserContext, SnackbarContext, ModalContext } from "../../contexts";
import { useInput, usePagination } from "../../hooks";
import { FormInput, Button, Paging, Board } from "../../components";
import API from "../../api";
import Util from "../../util";
import { ToastViewer } from "../../components/form/ToastViewer";
import { useTranslation } from "react-i18next";

export const BoardDetailPage = (props) => {
  const { t } = useTranslation(); // useTranslation 훅을 사용하여 t 함수를 가져옴

  const params = useParams();
  const { slug } = params;
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { userInfo } = useContext(UserContext);
  const { updateModalOpen, updateModalData, closeModal } = useContext(
    ModalContext
  );
  useEffect(() => {
    setLoading(true);

    API.Board.GetGetFreeBoardDetail(slug).then(
      (res) => {
        let result = res.data;
        console.log("Board.GetNotcieDetail", result);
        setData(result);
        setLoading(false);
      },
      (err) => {
        setLoading(false);

        updateSnackbar({
          type: "error",
          open: true,
          message: err.data.message,
        });
      }
    );
  }, [slug]);

  console.log("data!", data);
  console.log("userInfo", userInfo);

  if (!data) {
    return (
      <article className="board-detail-page">
        <div className="board__wrapper">
          <div className="loader big color"></div>
        </div>
      </article>
    );
  }
  return (
    <article className="board-detail-page">
      <div className="board__wrapper">
        <article className="board__item">
          <section className="title__box">
            <h3>{data.title}</h3>
          </section>
          <section className="viewer__box">
            <ToastViewer value={data.content} meta={{}}></ToastViewer>
          </section>
          {data.fileList && data.fileList.length > 0 && (
            <section className="file__box">
              <header>
                <span>{t("system.label.file")}</span>
              </header>
              <ul>
                {data.fileList.map((item, index) => {
                  return (
                    <li
                      key={"notice-file-" + index}
                      onClick={() => {
                        window.open(item, "_blank");
                      }}
                    >
                      <span>{Util.Form.GetFilename(item)}</span>
                    </li>
                  );
                })}
              </ul>
            </section>
          )}
        </article>

        <section className="button__box">
          <Button
            label={t("notice_detail.button.list")}
            onClick={() => {
              history.push("/board/list");
            }}
          ></Button>
          {userInfo && userInfo.no === data.memberNo && (
            <Button
              type="delete"
              style={{ marginLeft: 16 }}
              label={t("free_board.detail.button.delete")}
              onClick={() => {
                updateModalData({
                  type: "delete",
                  title: t("free_board.detail.confirm_delete.title"),
                  content: t("free_board.detail.confirm_delete.desc"),
                  onConfirm: () => {
                    console.log("work");
                    API.Board.DeleteFreeBoard(slug).then(
                      (res) => {
                        updateSnackbar({
                          type: "success",
                          open: true,
                          message: t(
                            "free_board.detail.confirm_delete.alert_success"
                          ),
                        });
                        history.push("/board/list");
                        closeModal();
                      },
                      (err) => {
                        closeModal();

                        updateSnackbar({
                          type: "error",
                          open: true,
                          message: err.data.message,
                        });
                      }
                    );
                  },
                });
                updateModalOpen(true);
              }}
            ></Button>
          )}
        </section>
      </div>
    </article>
  );
};
