import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserContext, SnackbarContext } from "../../contexts";
import { useInput, usePagination } from "../../hooks";
import {
  FormInput,
  Button,
  ToastEditor,
  FileMultipleUploader,
  FormArea,
} from "../../components";
import API from "../../api";
import Util from "../../util";
import { useTranslation } from "react-i18next";

export const MypageQnaAddPage = (props) => {
  const { t } = useTranslation(); // useTranslation 훅을 사용하여 t 함수를 가져옴
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { userInfo, refreshUserInfo } = useContext(UserContext);
  const [form, onChangeForm, resetForm] = useInput({
    title: "",
    content: "",
    fileList: [],
  });

  const requestAdd = () => {
    let body = {
      title: form.title,
      content: form.content,
      fileList: form.fileList,
    };

    if (!body.title) {
      updateSnackbar({
        type: "error",
        open: true,
        message: t("mypage.qna_add.alert.no_title"),
      });
      return;
    }

    if (!body.content) {
      updateSnackbar({
        type: "error",
        open: true,
        message: t("mypage.qna_add.alert.no_content"),
      });
      return;
    }

    API.Member.PutInquiry(body).then(
      (res) => {
        console.log("res", res);
        let result = res.data;
        console.log("result", result);
        updateSnackbar({
          type: "success",
          open: true,
          message: t("mypage.qna_add.alert.success"),
        });
        history.push("/mypage/qna/detail/" + result.no);
      },
      (err) => {
        updateSnackbar({
          type: "error",
          open: true,
          message: err.data.message,
        });
      }
    );

    console.log("requestAdd", body);
  };

  return (
    <article className="mypage-qna-add-page">
      <section className="title__box">
        <h3>{t("mypage.qna_add.title")}</h3>
      </section>

      <section className="form__box">
        <FormInput
          label={t("form.label.title")}
          value={form.title}
          name={"title"}
          onChange={onChangeForm}
        ></FormInput>
        <FormArea
          label={t("form.label.content")}
          value={form.content}
          name={"content"}
          onChange={onChangeForm}
        ></FormArea>
        <FileMultipleUploader
          value={form.fileList}
          onChange={onChangeForm}
          name="fileList"
          meta={{
            label: t("system.label.file"),
            max: 10,
            path: "qna",
          }}
        ></FileMultipleUploader>
      </section>

      <section className="button__box">
        <Button
          label={t("mypage.qna_add.button")}
          onClick={() => {
            requestAdd();
          }}
        ></Button>
      </section>
    </article>
  );
};
